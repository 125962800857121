import { Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { CgSearch } from "react-icons/cg";
import { FiMenu, FiSearch } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swlogo from "../../asset/images/swlogo.svg";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { citySelector } from "../../redux/reducer/cityName";
import "./navbar.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const { cityName } = useSelector(citySelector);
 

  let prevScrollpos = window.pageYOffset;
  const [toggle, setToggle] = useState(false);

  const [open, setOPen] = useState(false);
  const toggles = () => {
    setOPen(!open);
  };
  const handleMenu = () => {
    setToggle(!toggle);
  };

  const option = [
    { value: "Gurgaon", label: "Gurgaon" },
    { value: "Delhi", label: "Delhi" },
    { value: "Pune", label: "Pune" },
    { value: "Bangalore", label: "Bangalore" },
    { value: "Noida", label: "Noida" },
    { value: "Mumbai", label: "Mumbai" },
  ];

  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setOptions(cityName);
    setFilteredOptions(cityName);
  }, []);

  function handleInputChange(event) {
    const value = event.target.value.toLowerCase();
    const filtered = options.filter((option) =>
      option?.cityName.toLowerCase().includes(value)
    );
    setFilteredOptions(filtered);
    setShowDropdown(true);
  }

  function handleOptionClick(option) {
    console.log("qweqweqwe ::", option);
    setShowDropdown(false);
  }

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div>
      <div className="header" id="navbar">
        <div className="desktopHide">
          <div className="navbarMobile">
            <span className="navbarMobileSearch">
              <FiSearch size={"24px"} />
            </span>
            <Link to="/">
              <a href className="navbarMobileLogo">
                <img
                  className="navImg"
                  loading="loaded"
                  src={Swlogo}
                  alt="img"
                />
              </a>
            </Link>
            <span
              className={`navbarMobileMenu ${toggle && "toggle"} `}
              onClick={handleMenu}
            >
              {!toggle ? (
                <FiMenu size={"24px"} />
              ) : (
                <IoCloseOutline size={"24px"} />
              )}
            </span>
          </div>
          <div
            className="navbarMobileOverlay"
            style={{
              opacity: "1",
              visibility: "inherit",
              display: !toggle ? "none" : "block",
            }}
          >
            <div
              className={`navMobileAccordian conainer ${toggle && "toggle"}`}
              style={{ opacity: "1", visibility: "inherit" }}
            >
              <div>
                <Link to="/property-in-india">
                  <div className="footer-3-p" style={{ color: "white" }}>
                    PROPERTIES <AiOutlinePlus onClick={toggles} />
                    {open && (
                      <div>
                        <p>Luxury Outlook 2022</p>
                        <p>About Us</p>
                        <p>Giving Back</p>
                        <p>Press</p>
                        <p>Join Us</p>
                        <p>Luxury Real Estate</p>
                      </div>
                    )}
                  </div>
                </Link>
                <Link to="/blog">
                  <div className="footer-3-p" style={{ color: "white" }}>
                    BLOGS <AiOutlinePlus onClick={toggles} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="propertyPageNavbarContainer">
          <Link to="/">
            <a href className="navLogo2">
              <img
                className="navImg2"
                loading="loaded"
                src={Swlogo}
                alt="SeedWill Logo"
              ></img>
            </a>
          </Link>
          <Divider orientation="vertical" />
{/* 
          {window.location.pathname.includes("india") ? (
            <>
              <p
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  cursor: "pointer",
                  margin: "0 auto",
                  marginLeft: "20px",
                  display: "inline-flex",
                  color: "black!important",
                  className:"buy"
                }}
              >
                Buy in India <BiChevronDown size={18} />
              </p>
              {isOpen && (
                <div ref={ref}>
                  <div className="navbar-tooltip"></div>
                  <div className="citydropdown">
                    <input
                      type="text"
                      placeholder="Select City"
                      onChange={handleInputChange}
                      className="citydropdown-input"
                    />
                    {showDropdown && (
                      <div className="citydropdown-input-ul-div">
                        {filteredOptions?.map((item) => (
                          <ul className="citydropdown-input-ul">
                            <li
                              className="citydropdown-input-li"
                              key={item.id}
                              onClick={() =>
                                dispatch(fetchAllProjectList(item.id))
                              }
                            >
                              {item.cityName}
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <p
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  cursor: "pointer",
                  margin: "0 auto",
                  marginLeft: "20px",
                  display: "inline-flex",
                  color: "black",
                }}
              >
                Buy in Gurgaon <BiChevronDown size={18} />
              </p>
              {isOpen && (
                <div ref={ref}>
                  <div className="navbar-tooltip"></div>
                  <div className="citydropdown">
                    <input
                      type="text"
                      placeholder="Select City"
                      onChange={handleInputChange}
                      className="citydropdown-input"
                    />
                    {showDropdown && (
                      <div className="citydropdown-input-ul-div">
                        {filteredOptions?.map((item) => (
                          <ul className="citydropdown-input-ul">
                            <li
                              className="citydropdown-input-li"
                              key={item.id}
                              onClick={() =>
                                dispatch(fetchAllProjectList(item.id))
                              }
                            >
                              {item.cityName}
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )} */}

          {/* <div className="property-searchbar">
            <div className="container_form">
              <div className="dropdown">
                <Select
                  className="home_input"
                  placeholder={<CgSearch size={20} />}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(29, 34, 30, 0.89)",
                      primary: "white",
                    },
                  })}
                  styles={{
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#D8E8D2",
                      borderRadius: "25px",
                      color: "#6AA74D",
                    }),
                  
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      ":hover": {
                        color: "#FFD194",
                        cursor: "pointer",
                      },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#6AA74D",
                      ":hover": {
                        backgroundColor: state.color,
                        color: "#6AA74D",
                        cursor: "pointer",
                      },
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#6AA74D",
                      ":hover": {
                        backgroundColor: state.color,
                        color: "#6AA74D",
                        cursor: "pointer",
                      },
                    }),
                  }}
                  options={option}
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div> */}

          <div className="navMenuContainer">
            <ul className="navMenu">
              <Link to="/how-we-work">
                <li className="navMenu-item">
                  <div className="nav-menu-button nav-menu-button-search">
                    How We Work
                  </div>
                </li>
              </Link>
              <li className="navMenu-item">
                <Link to="/property-in-india">
                  <div className="nav-menu-button nav-menu-button-search">
                    Properties
                  </div>
                </Link>
              </li>
              <Link to="/blog">
                <li className="navMenu-item">
                  <div className="nav-menu-button nav-menu-button-search">
                    Blog
                  </div>
                </li>
              </Link>
              <Link to="/">
                <li className="navMenu-item">
                  <div className="nav-menu-link">
                    <Link to="/sellwithus">
                      <a href className="nav-button">
                        Login
                      </a>
                    </Link>
                  </div>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
