import "./Searchbar.scss"
import { FaChevronDown } from 'react-icons/fa6'
import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchSummary } from "../../redux/action/summary";
import { sumarrySelector } from "../../redux/reducer/summary";
import { citySelector } from "../../redux/reducer/cityName";
import { Link } from "react-router-dom";
import { propertyTypeSelector } from "../../redux/reducer/propertyType";


export default function Searchbar() {
  const [selectedCity, setSelectedCity] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [toggle, setToggle] = useState(false)
  const [typeToggle, setTypeToggle] = useState(false)
  /* const [searchToggle, setSearchToggle] = useState(false)
  const [boolean, setBoolean] = useState(false) */
  const [searchKey, setSearchKey] = useState("");
  const [city, setCity] = useState("")
  const [type, setType] = useState("")
  const [cityId, setCityId] = useState("")
  const refOne = useRef()
  const refTwo = useRef()
  const refThree = useRef()
  const FinalDispatchButton = () => {
    console.log("Dispatched FinalState.......");
  };

  const dispatch = useDispatch()
  const { summary } = useSelector(sumarrySelector)
  const { cityName } = useSelector(citySelector);
  const { propertyType } = useSelector(propertyTypeSelector)
  const [cityClickCount, setCityClickCount] = useState(0);
  const [typeClickCount, setTypeClickCount] = useState(0);



  const propertyList = ["Residential", "Commercial", "Office", "Shop", "Plots", "Penthouse", "Villa"]

  useEffect(() => {
    const handler = (e) => {
      if (!refOne.current.contains(e.target)) {
        setToggle(false);
        console.log(toggle)
      }
      if (!refTwo.current.contains(e.target)) setTypeToggle(false)
      if (!refThree.current.contains(e.target)) setInputFocused(false)
    };

    document.body.addEventListener("mousedown", handler);

    return () => {
      document.body.removeEventListener("mousedown", handler);
      console.log("hey")
    }
  }, [])


  const handleCityDropdownClick = () => {
    setCityClickCount((prevClickCount) => prevClickCount + 1);
    setTypeClickCount(0); // Reset typeClickCount to 0
    setToggle((prevToggle) => !prevToggle);
    if (toggle) setTypeToggle(false); // Close the other dropdown if this one is clicked again
  };

  const handleTypeDropdownClick = () => {
    if (typeToggle) {
      setTypeToggle(false); // Close the dropdown if it's already open
    } else {
      setTypeClickCount((prevClickCount) => prevClickCount + 1);
      setCityClickCount(0); // Reset cityClickCount to 0
      setTypeToggle(true); // Open the dropdown
      if (toggle) setToggle(false); // Close the other dropdown if it's open
    }
  };




  /* useEffect(() => {
    if(typeToggle===true || toggle===true){
       setBoolean(true)
    } else setBoolean(false)
    
  },[toggle, typeToggle]) */


  const handleChangeType = (e) => {
    if (e.target.checked) {
      setType(e.target.value);
    } else {
      setType("");
    }
    handleTypeDropdownClick(); // Close the type dropdown when an item is clicked
  };


  const handleChange = (e) => {
    if (e.target.checked) {
      setCity(e.target.value);
      setCityId(e.target.id);
      setSelectedCity(e.target.value);
    } else {
      setCity("");
      setCityId("");
      setSelectedCity("");
    }

    handleCityDropdownClick(); // Close the city dropdown when an item is clicked
  };

  useEffect(() => {
    const handleSearchKey = () => {
      dispatch(fetchSummary(cityId, type, searchKey));
    };
    handleSearchKey();
  }, [searchKey, cityId, type]);

  const handleInputFocus = () => {
    setInputFocused(true);
  };


  return (
    <div className="searchBar-con">

      <div className="searchBar">
        <div className="searchBarLeft">
          <div className="type leftItem">
            <label htmlFor="cb0" className="searchBarLabel" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "13px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "letterSpacing": "1.365px",
              "textTransform": "uppercase"
            }}>Property Type
            </label>
            <div className="type-select" onClick={() => {
              setTypeToggle(!typeToggle)
              toggle ? setToggle(false) : setToggle(toggle)
              console.log(typeToggle)
            }}>
              <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "letterSpacing": "1.365px",

              }}>{`${type ? type : 'Any'}`}</span>
              <FaChevronDown className={`${typeToggle ? 'transform' : 'trans'}`} />
            </div>

          </div>

          <div className="cities leftItem">
            <label className="searchBarLabel" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "13px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "letterSpacing": "1.365px",
              "textTransform": "uppercase"
            }}>City
            </label>
            <div className="type-select" onClick={() => {
              setToggle(!toggle)
              typeToggle ? setTypeToggle(false) : setTypeToggle(typeToggle)
            }}>
              <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "letterSpacing": "1.365px",

              }}>{`${city ? city : ' Any'}`}</span>
              <FaChevronDown className={`${toggle ? 'transform' : 'trans'}`} />
            </div>
          </div>
          <div className="search" style={{ border: "none" }}>
            <label className="searchBarLabel" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "13px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "letterSpacing": "1.365px",
              "textTransform": "uppercase"
            }}>Search
            </label>
            <input style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "13px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "letterSpacing": "1.365px",
              "textTransform": "uppercase", "paddingBottom": "1px"
            }}
              className="searchBar-input"
              placeholder={
                selectedCity.length > 0
                  ? `property in ${selectedCity}`
                  : "Search any property"
              }

              type="text"
              value={searchKey}
              onFocus={handleInputFocus}
              /* onBlur={() => setInputFocused(false)} */
              onChange={(e) => {
                setSearchKey(e.target.value)
                searchKey.length > 0 ? setToggle(false) : setToggle(toggle)
                searchKey.length > 0 ? setTypeToggle(false) : setTypeToggle(typeToggle)
              }}
            />
          </div>
        </div>
        <div className="searchBarRight">
          <button onClick={FinalDispatchButton}>SEARCH PROPERTIES</button>
        </div>
      </div>

      {inputFocused ? (
        <div className="searchResult" ref={refThree}>
          <div className="search-result-li-container">
            {summary.length > 0
              ? summary?.map((item, id) => {
                return (
                  <Link to={`/${item.URL}`} className="linkclass">
                    <div key={id} className="searchResult-li">
                      <span className="search-result-title" >
                        {item.title}
                      </span>
                      <span className="search-result-property-type" style={{
                        "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "16px",
                        "fontStyle": "normal",
                        "fontWeight": "300",
                        "lineHeight": "normal",
                        "textTransform": "capitalize"
                      }}>
                        {item.SearchName}
                      </span>
                    </div>
                  </Link>
                );
              })
              : "no result found!"}
          </div>
        </div>
      ) : null}

<div className={`checkbox-wrapper1 ${toggle ? 'open' : 'none'}`} id="cb0" ref={refOne}>
 
  {cityName?.map((item, idx) => {
    <h1>abhi</h1>
    return (
      <div className="checkbox-con" id="" key={idx} >
        
        <label htmlFor={item.id} style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "300",
          "lineHeight": "normal",
          "textTransform": "capitalize"
        }} >{item.cityName}</label>
        <input type="checkbox" id={item.id} checked={city === item.cityName} value={item.cityName} onChange={handleChange} className="circular-checkbox" />
      </div>
    )
  })}
</div>


      <div className={`checkbox-wrapper ${typeToggle ? 'open' : 'none'}`} id="cb1" ref={refTwo}>
        {propertyList?.map((item, idx) => {
          return (
            <div className="checkbox-con" key={idx}>
              <label htmlFor={item.id}>{item}</label>
              <input type="checkbox" id={item.id} checked={type === item} value={item} onChange={handleChangeType} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
