import {
  getBlogContent,
  getBlogContentSuccess,
  getBlogContentFailure,
} from "../reducer/blogContent";
import axios from "axios";

export const fetchBlogContent = (blogid, blogslug) => {
  let id = blogid ? blogid : "";
  let slugpath = blogslug ? blogslug : "";
  return (dispatch) => {
    dispatch(getBlogContent());
    axios
      .get(`/blogDetails?id=${id}&slug=${slugpath}`)
      .then((response) => {
        dispatch(getBlogContentSuccess(response.data));
      })
      .catch((error) => dispatch(getBlogContentFailure(error)));
  };
};
