import {
  getSummary,
  getSummarySuccess,
  getSummaryFailure,
} from "../reducer/summary";

import axios from "axios";

export const fetchSummary = (cityId, type, searchKey) => {
  let city = cityId ? cityId : "";
  let search = searchKey ? searchKey : "";
  let propertyType = type ? type : ""

  // console.log(`cityIDinSummaryID`, city);
  return (dispatch) => {
    dispatch(getSummary());
    axios
      // .get(`getResults?city=${city}&keyword=${search}`)
      .get(`/getResults?city=${city}&type=${propertyType}&keyword=${search}`)
      .then((response) => dispatch(getSummarySuccess(response.data)))

      .catch((error) => dispatch(getSummaryFailure(error)));
  };
};
