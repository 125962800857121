import React from 'react'
import "./style.scss";
function Firstsection() {
  return (
    <div className="PropertyServices">

      <div className="grid-container">
        <div className="grid-item"><span style={{
          "color": "#000",
          "fontFamily": "Gotu",
          "fontSize": "28px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal"
        }}>16+</span><br /><span style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>CITIES</span></div>
        <div class="vertical-line"></div>
        <div className="grid-item"> <span style={{
          "color": "#000",
          "fontFamily": "Gotu",
          "fontSize": "28px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal"
        }}>1000+</span><br /><span style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>LOCATION</span></div>
        <div class="vertical-line"></div>
        <div className="grid-item"><span style={{
          "color": "#000",
          "fontFamily": "Gotu",
          "fontSize": "28px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal"
        }}>5000+</span><br /><span style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>PROJECTS</span> </div>
        <div class="vertical-line"></div>

        <div className="grid-item"><span style={{
          "color": "#000",
          "fontFamily": "Gotu",
          "fontSize": "28px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal"
        }}>800+CR</span><br /><span style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>PROPERTIES </span></div>
        <div class="vertical-line"></div>
        <div className="grid-item"><span style={{
          "color": "#000",
          "fontFamily": "Gotu",
          "fontSize": "28px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal"
        }}>300+K</span><br /><span style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "16px",
          "fontStyle": "normal",
          "fontWeight": "400",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>INTENTS </span></div>

      
      </div>


    </div>
  )
}

export default Firstsection;