import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Button,
  Divider,
  IconButton,

} from "@mui/material";
import { FaRegHeart } from "react-icons/fa";

import { MdIosShare, MdVerified } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../../asset/images/agent.jpeg";
import { fetchAllProjectList } from "../../../redux/action/allProjectList";
import { fetchProduct } from "../../../redux/action/product";
import { allProjectListSelector } from "../../../redux/reducer/allProjectList";

import "./style.scss";



import AdvanceTab from "./AdvanceTab";

const TabProperty = () => {
  const addImageFallback = (event) => {
    event.currentTarget.src =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjCN1Fg1nNOg8sc76MR64L7ZBPWuYs6c1BGg&usqp=CAU";
  };


  const [showUi, setShowUi] = useState(false);
  const handleshowUi = () => {
    setShowUi(true);
  };
  const handleshowUiTwo = () => {
    setShowUi(false);
  };
  const [active, setActive] = useState("");
  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const dispatch = useDispatch();
  const { allProjectList } = useSelector(allProjectListSelector);
  const [page, setPage] = useState(1);

  // infinitescrolling
  useEffect(() => {
    dispatch(fetchAllProjectList("", "", "", "", page));
  }, [page]);

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.offsetHeight;
      const scrollPoint = window.scrollY + windowHeight;
      if (scrollPoint >= fullHeight) {
        setPage((prev) => prev + 1);
        setCard((prev) => [...prev, ...allProjectList]);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  const [card, setCard] = useState([]);


  return (
    <>
      <div className="property1">
  <div>
    <Box>
      {/* First Image (70% width) */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          {allProjectList.slice(0, 1).map((item, index) => (
            <div
              onClick={() => dispatch(fetchProduct(item.id))}
              key={index}
              style={{ height: "341px" }}
              className="abhi2"
            >
              <Link
                to={`/property-in-${item.cityName.toLowerCase()}/${item.slug}-${item.id}`}
                className="linkclass"
              >
                <div className="card-list2">
                  <div className="card-item">
                    <p
                      className="price-tag1"
                      style={{
                        position: "absolute !important",
                        background: "#fff",
                        color: "#000",
                        cursor: "pointer",
                        display: "inline-block",
                        fontSize: "0.876rem",
                        height: "31px",
                        lineHeight: "31px",
                        marginTop: "31%",
                        padding: "0 0.667rem",
                        textAlign: "center",
                        right: "0",
                      }}
                    >
                      {item.name}
                    </p>
                    <img
                      className="image2"
                      src={item.proImg}
                      alt="card"
                      style={{ height: "268px" }} // Set a fixed height
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Grid>
        {/* Second Image (30% width) */}
        <Grid item xs={12} sm={4}>
          {allProjectList.slice(1, 2).map((item, index) => (
            <div onClick={() => dispatch(fetchProduct(item.id))} key={index}>
              <Link
                to={`/property-in-${item.cityName.toLowerCase()}/${item.slug}-${item.id}`}
                className="linkclass"
              >
                <div className="card-list2">
                  <div className="card-item">
                    <p
                      className="price-tag1 price-tag2"
                      style={{
                        position: "absolute !important",
                        background: "#fff",
                        color: "#000",
                        cursor: "pointer",
                        display: "inline-block",
                        fontSize: "0.876rem",
                        height: "31px",
                        lineHeight: "31px",
                        marginTop: "63%",
                        padding: "0 0.667rem",
                        textAlign: "center",
                        right: "0",
                      }}
                    >
                      {item.name}
                    </p>
                    <img
                      className="image2"
                      src={item.proImg}
                      alt="card"
                      style={{ height: "268px" }} // Set a fixed height
                    />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Grid>
      </Grid>

      {/* Rest of the images */}
      <Grid className="abhi" container spacing={2}>
        {/* Adjust spacing value as needed */}
        {allProjectList.slice(2, 5).map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <div onClick={() => dispatch(fetchProduct(item.id))}>
              <Link
                to={`/property-in-${item.cityName.toLowerCase()}/${item.slug}-${item.id}`}
                className="linkclass"
              >
                <div className="card-list2">
                  <div className="card-item">
                    <p
                      className="price-tag1 price-tag2"
                      style={{
                        position: "absolute !important",
                        background: "#fff",
                        color: "#000",
                        cursor: "pointer",
                        display: "inline-block",
                        fontSize: "0.876rem",
                        height: "31px",
                        lineHeight: "31px",
                        marginTop: "63%",
                        padding: "0 0.667rem",
                        textAlign: "center",
                        right: "0",
                      }}
                    >
                      {item.name}
                    </p>
                    <img
                      className="image2"
                      src={item.proImg}
                      alt="card"
                      style={{ height: "268px" }} // Set a fixed height
                    />
                  </div>
                </div>
              </Link>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  </div>
</div>


     
    </>
  );
};

export default TabProperty;
