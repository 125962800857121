

import React, { useEffect } from "react";
import { Box } from '@mui/material';
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { MdIosShare, MdVerified } from "react-icons/md";

import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import agent from "../../../asset/images/agent.jpeg";

import "./style.scss";
import { BiHeart } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { trendingPropertiesSelector } from "../../../redux/reducer/trendingProperties";
import { fetchTrendingProperties } from "../../../redux/action/trendingProperties";

import { fetchProduct } from "../../../redux/action/product";
import { fetchAllProjectList } from "../../../redux/action/allProjectList";



const LatestLaunch = () => {


  const dispatch = useDispatch();
  const { trendingProperties } = useSelector(trendingPropertiesSelector);

  useEffect(() => {
    dispatch(fetchTrendingProperties());
  }, [dispatch]);

  return (
    <>
      <div className="PropertyServices1">

        {/* <p className="reconditionProperties-head">
                PROPERTIES  <div class="line"></div>
            </p> */}

        <div class="grid-container1">
          <div class="grid-item">
            <div className="card-body">
              <div className="row" style={{
                "marginTop": "10px"
              }}>
                <div className="card-title">
                  <div className="text-with-bar" style={{
                    display: "inline-block",
                    position: "relative",
                    marginLeft: "auto", // Align to the right by setting marginLeft to "auto"
                    marginRight: "0",  // Reset marginRight
                    textAlign: "right", // Align text to the right
                  }}>
                    <p style={{
                      "marginBottom": "-5px"
                    }}> properties </p>
                    <hr className="hr" />
                  </div>
                  <p style={{
                    fontSize: "25px",
                    color: "rgb(0, 0, 0)",
                    fontFamily: "Gotu",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                    marginLeft: "auto", // Align to the right by setting marginLeft to "auto"
                    marginRight: "0",  // Reset marginRight
                    marginTop: "7px"
                  }}> TRENDING PROPERTIES</p>
                </div>

              </div>


              <p className="content2" style={{
                color: "rgb(0, 0, 0)",
                fontFamily: "Montserrat",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",


                marginTop: "0",
                wordSpacing: "4px", // Add space between words
              }}>
                <span style={{
                  color: "rgb(0, 0, 0)",
                  fontFamily: "Montserrat",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",

                }}>O</span>ur properties each have their own unique design aesthetic, providing an aspirational lifestyle within a thriving community, supported by Seedwill's community management team.
              </p>



              <div
                className="btn-group"
                onClick={() => dispatch(fetchAllProjectList("", "", "", 1))}
              >
                <Link to={`/property-in-india/trending`}>
                  <button className="custom-btn btn-2" style={{
                    "width": "215px"
                  }}>VIEW ALL PROPERTIES</button>
                </Link>

              </div>


            </div>
          </div>
          <div class="grid-item">
            <div className="similar-projects-button-container">
              <button className="similar-projects-prev">
              <svg stroke="currentColor" fill="none" stroke-width="1" viewBox="0 0 24 24" aria-hidden="true" height="70" width="35" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
              </button>
              <button className="similar-projects-next">
              <svg stroke="currentColor" fill="none" stroke-width="1" viewBox="0 0 24 24" aria-hidden="true" height="70" width="35" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
              </button>
            </div>

            <Swiper
              slidesPerView={1}
              spaceBetween={1}
              slidesPerGroup={1}
              loopFillGroupWithBlank={false}
              rewind={false}
              speed={1100}
              grabCursor={true}
              breakpoints={{
                2500: {
                  slidesPerView: 2.9,
                  spaceBetween: 6,
                },
                2000: {
                  slidesPerView: 2.5,
                  spaceBetween: 6,
                },
                1900: {
                  slidesPerView: 2.5,
                  spaceBetween: 6,
                },
                1700: {
                  slidesPerView: 2.5,
                  spaceBetween: 6,
                },
                1500: {
                  slidesPerView: 2.5,
                  spaceBetween: 6,
                },
                1400: {
                  slidesPerView: 2.5,
                  spaceBetween: 6,
                },
                1300: {
                  slidesPerView: 2.3,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 2.2,
                  spaceBetween: 12,
                },
                1100: {
                  slidesPerView: 2,
                  spaceBetween: 12,
                },
                1000: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                890: {
                  slidesPerView: 11,
                  spaceBetween: 8,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                578: {
                  slidesPerView: 1,
                  spaceBetween: 7,
                },
                424: {
                  slidesPerView: 1,
                  spaceBetween: 3,
                },
                375: {
                  slidesPerView: 1,
                },
              }}
              navigation={{
                nextEl: ".similar-projects-next",
                prevEl: ".similar-projects-prev",
              }}
              modules={[Pagination, Navigation]}
            >
              {trendingProperties?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <>
                      <div className="image-card"
                        key={index}
                        onClick={(e) => dispatch(fetchProduct(item.id))}>
                        <Link
                          to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                            }-${item.id}`}
                          className="linkclass"
                        >
                          <div className="image-container">
                            <img src={item.proImg} />
                          </div>
                          <div className="text-container">
                            <div className="text" style={{
                              "color": "#000",
                              "fontFamily": "Montserrat",
                              "fontSize": "11px",
                              "fontStyle": "normal",
                              "fontWeight": "400",
                              "lineHeight": "normal",
                              "letterSpacing": "0.77px",
                              "textTransform": "uppercase"
                            }}>
                              {item.name}
                            </div>
                            <div className="vertical-bar" style={{
                              "width": "61px",
                              "height": "2%",
                              "backgroundColor": "rgb(51, 51, 51)"
                            }}></div>
                          </div>
                        </Link>
                      </div>




                      {/* </div> */}


                    </>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestLaunch;