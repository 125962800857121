// src/reducers/projectReducer.js

import { createSlice } from '@reduxjs/toolkit';
import { fetchProjects } from '../action/newsearch';

const initialState = {
  cities: [],
  selectedCity: '',
  propertyTypes: [],
  selectedPropertyType: '',
  projects: [],
  filteredProjects: [],
  loading: false,
  error: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
      state.filteredProjects = state.projects.filter(
        (project) => project.city === action.payload
      );
    },
    setSelectedPropertyType: (state, action) => {
      state.selectedPropertyType = action.payload;
      state.filteredProjects = state.projects.filter(
        (project) => project.property_type === action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
        state.filteredProjects = action.payload;
        state.error = null;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedCity, setSelectedPropertyType } = projectSlice.actions;
export default projectSlice.reducer;
