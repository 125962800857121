import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./style.scss";
import { BiHeart } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { nearByPropertySelector } from "../../../redux/reducer/nearByProperty";
import { fetchNearByProperty } from "../../../redux/action/nearByProperty";
import { Link } from "react-router-dom";
import { fetchProduct } from "../../../redux/action/product";
import { fetchAllProjectList } from "../../../redux/action/allProjectList";
import { fetchCity } from "../../../redux/action/cityName";
import { citySelector } from "../../../redux/reducer/cityName";
import axios from 'axios';
function NearByProperties() {
  const dispatch = useDispatch();
  const { nearByProperty } = useSelector(nearByPropertySelector);
  const { cityName } = useSelector(citySelector);

  const addImageFallback = (event) => {
    event.currentTarget.src =
      "https://www.seedwill.co/assets/img/be8b9276375372544b708a47fd37becc.png";
  };

  // current location...........//..............
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [city, setCity] = useState();

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation NOT suppoted");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to get location.");
        }
      );
    }
  };

  let geoUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`;

  fetch(geoUrl)
    .then((response) => response.json())
    .then((data) => setCity(data?.city));

  useEffect(() => {
    dispatch(fetchCity());
    getLocation();
  }, []);

  const uniqueObjects = [
    ...new Map(cityName.map((item) => [item, item?.cityName])).values(),
  ];

  function moveStringToFirstPosition(city, uniqueObjects) {
    const index = uniqueObjects.findIndex(
      (str) => str.toLowerCase() === city?.toLowerCase()
    );
    if (index !== -1) {
      if (index !== 0) {
        const targetElement = uniqueObjects[index];
        uniqueObjects = uniqueObjects
          .slice(0, index)
          .concat(uniqueObjects.slice(index + 1));
        uniqueObjects.unshift(targetElement);
      }
    } else {
      if (uniqueObjects[0]?.toLowerCase() !== "gurgaon") {
        uniqueObjects.unshift("gurgaon");
      }
    }
    return uniqueObjects;
  }

  const newArr = moveStringToFirstPosition(city, uniqueObjects);
  useEffect(() => {
    dispatch(fetchNearByProperty(newArr[0]?.toLowerCase()));
  }, [newArr[0]]);

  //......................//..............//...................

  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://nodeapi.seedwill.co/api/v1/nearbyProperty?city=gurgaon"
        );
        setData(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  // console.log(`data`, data)

  return (
    <div className="near-by-properties">
      <div>
        <h3>Near By Properties</h3>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontFamily: "sans-serif", color: "#000000" }}>
          <span
            style={{
              textDecoration: "underline",
              textUnderlineOffset: "5px",
              textDecorationThickness: "2px",
              textDecorationColor: "#FFD194",
            }}
          >
            We sugg
          </span>
          est you to near by properties
        </p>

        <div
          className="Seeallproperties"
          onClick={() =>
            dispatch(fetchAllProjectList(newArr[0]?.toLowerCase()))
          }
        >
          <Link
            to={`/property-in-${nearByProperty[0]?.cityName?.toLowerCase()}`}
          >
            <p className="Seeallproperties-para">See all properties</p>
          </Link>

          <p className="Seeallproperties-lefticon">
            <HiOutlineArrowNarrowRight size={30} />
          </p>
        </div>
      </div>

      <div className="near-by-properties-cards">
        {nearByProperty.length < 6
          ? nearByProperty?.map((item, index) => {
              if (index < 6) {
                return (
                  <div
                    key={index}
                    onClick={(e) => dispatch(fetchProduct(item.id))}
                  >
                    <Link
                      to={`/property-in-${item.cityName.toLowerCase()}/${
                        item.slug
                      }-${item.id}`}
                      className="linkclass"
                    >
                      <div className="near-by-properties-card">
                        {/* <div> */}
                        <img
                          src={item.proImg}
                          alt="Avatar"
                          className="near-by-properties-img"
                          onError={addImageFallback}
                        />
                        <div className="near-by-properties-container">
                          <p style={{ fontWeight: "600" }}>{item.name}</p>
                          <p style={{ color: "#8B8B8B" }}>by {item.DevName}</p>
                          <p>2 BHK FLAT with fully furnished</p>
                          <p style={{ color: "#8B8B8B" }}>
                            {" "}
                            {item.location} {item.cityName}{" "}
                          </p>
                          <p style={{ color: "#8B8B8B" }}>{item.area}</p>
                          <p style={{ fontWeight: "600" }}>{item.price}</p>
                        </div>
                        <BiHeart
                          size={35}
                          color={"#D8D8D8"}
                          style={{ padding: "5px" }}
                        />
                      </div>
                    </Link>
                  </div>
                );
              } else return "";
            })
          : data?.map((item, index) => {
              if (index < 6) {
                return (
                  <div
                    key={index}
                    onClick={(e) => dispatch(fetchProduct(item.id))}
                  >
                    <Link
                      to={`/property-in-${item.cityName.toLowerCase()}/${
                        item.slug
                      }-${item.id}`}
                      className="linkclass"
                    >
                      <div className="near-by-properties-card">
                        {/* <div> */}
                        <img
                          src={item.proImg}
                          alt="Avatar"
                          className="near-by-properties-img"
                          onError={addImageFallback}
                        />
                        <div className="near-by-properties-container">
                          <p style={{ fontWeight: "600" }}>{item.name}</p>
                          <p style={{ color: "#8B8B8B" }}>by {item.DevName}</p>
                          <p>2 BHK FLAT with fully furnished</p>
                          <p style={{ color: "#8B8B8B" }}>
                            {" "}
                            {item.location} {item.cityName}{" "}
                          </p>
                          <p style={{ color: "#8B8B8B" }}>{item.area}</p>
                          <p style={{ fontWeight: "600" }}>{item.price}</p>
                        </div>
                        <BiHeart
                          size={35}
                          color={"#D8D8D8"}
                          style={{ padding: "5px" }}
                        />
                      </div>
                    </Link>
                  </div>
                );
              } else return "";
            })}
      </div>
    </div>
  );
}

export default NearByProperties;
