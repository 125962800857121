import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { fetchAllProjectList } from "../../../redux/action/allProjectList";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";



const AdvanceTab = () => {
  
  const [expandedPropertyType, setExpandedPropertyType] = useState(true);
 
  const [active, setActive] = useState("");

  

  
  const handleExpandPropertyType = () => {
    setExpandedPropertyType(!expandedPropertyType);
  };
  

 
  

  


 
  const dispatch = useDispatch();

 

 

  

  return (
    <>
      {/* <div className="filter-container"> */}
       

       
        

        {/* <div className="filter-type-box">
          <div className="filter-text-heading">Property Type</div>
          <ExpandMore
            expand={expandedPropertyType}
            aria-expanded={expandedPropertyType}
            onClick={handleExpandPropertyType}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div> */}
        {/* <Collapse in={expandedPropertyType}> */}
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              // onClick={handleClick}
              style={{
                border:'none'
              }}
              onClick={() => dispatch(fetchAllProjectList("", "commercial"))}
            >
              COMMERCIAL
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              style={{
                border:'none'
              }}
              onClick={() => dispatch(fetchAllProjectList("", "residential"))}
            >
              RESIDENTIAL
            </button>
            <button
              key={3}
              id={"3"}
              className={active === "3" ? "active" : "not-active"}
              style={{
                border:'none',
                
              }}
              // onClick={handleClick}
              onClick={() => dispatch(fetchAllProjectList("", "plots"))}
            >
              PLOTS
            </button>
            {/* <button
              key={3}
              id={"3"}
              className={active === "4" ? "active" : "not-active"}
              style={{
                border:'none'
              }}
             
              onClick={() => dispatch(fetchAllProjectList("", "luxurious"))}
            >
              LUXURIOUS
            </button> */}
            <button
              key={3}
              id={"3"}
              className={active === "4" ? "active" : "not-active"}
              style={{
                border:'none'
              }}
              // onClick={handleClick}
              onClick={() => dispatch(fetchAllProjectList("", "office"))}
            >
              OFFICE
            </button>
            {/* <button
              key={3}
              id={"3"}
              className={active === "4" ? "active" : "not-active"}
              style={{
                border:'none'
              }}
            
              onClick={() => dispatch(fetchAllProjectList("", "shop"))}
            >
              SHOP
            </button> */}
           
          </div>
        {/* </Collapse> */}

       
      
      {/* </div> */}

      
    </>
  );
};

export default AdvanceTab;
