import Aos from "aos";
import React, { useEffect } from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import PropertySlider from "../ProductPage/PropertySlider/PropertySlider";
import "./index.scss";
import ReconditionsProperties from "./RecommendedProperties/recommendedProperties";
import ExploreCategories from "./ExploreCategories/Index";
import TrendingProperties from "./TrendingProperties";
import NearByProperties from "./NearByProperties";
import PopularBuilder from "./PopularBuilder";
import OurPartners from "./OurPartners";
import PropertyServices from "./PropertyServices";
import BannerText from "./BannerText";
import New from "./New";
import Firstsection from "./FirstSection";
import LatestLaunch from "./LatestLaunch";
import ExcluciveProperties from "./ExcluciveProprties";

const HomePageDesc = () => {

  return (
    <>
<Firstsection/>
    <New/>
      {/* <ExploreCategories /> */}
      <LatestLaunch/>
      <ExcluciveProperties/>
      <ReconditionsProperties />
     
      {/* <NearByProperties /> */}
      {/* <TrendingProperties /> */}
      {/* <PopularBuilder /> */}
      <BannerText/>
      {/* <PropertyServices /> */}
      {/* <OurPartners /> */}

    </>
  );
};

export default HomePageDesc;
