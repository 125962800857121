import React from "react";
import { useSelector } from "react-redux";
import { productSelector } from "../../../../redux/reducer/product";
import "./gallery.scss";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Gallery = () => {
  const { product } = useSelector(productSelector);
  const addImageFallback = (event) => {
    event.currentTarget.src =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjCN1Fg1nNOg8sc76MR64L7ZBPWuYs6c1BGg&usqp=CAU";
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button
        className="image-gallery-icon image-gallery-left-nav"
        disabled={disabled}
        onClick={onClick}
        aria-label="Previous Slide"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="86" viewBox="0 0 28 86" fill="none">
          <rect width="28" height="86" transform="matrix(-1 0 0 1 28 0)" fill="white" />
          <path d="M16.6891 48.658C16.7877 48.5503 16.8659 48.4223 16.9192 48.2815C16.9725 48.1406 17 47.9897 17 47.8372C17 47.6847 16.9725 47.5337 16.9192 47.3929C16.8659 47.252 16.7877 47.1241 16.6891 47.0164L12.5645 42.499L16.6891 37.9816C16.8879 37.7639 16.9995 37.4687 16.9995 37.1608C16.9995 36.8529 16.8879 36.5577 16.6891 36.34C16.4904 36.1223 16.2208 36 15.9397 36C15.6586 36 15.389 36.1223 15.1902 36.34L10.3109 41.684C10.2123 41.7917 10.1341 41.9196 10.0808 42.0605C10.0275 42.2013 10 42.3523 10 42.5048C10 42.6573 10.0275 42.8083 10.0808 42.9491C10.1341 43.09 10.2123 43.2179 10.3109 43.3256L15.1902 48.6696C15.5942 49.112 16.2745 49.112 16.6891 48.658Z" fill="black" />
        </svg>
      </button>
    );
  };

  const renderRightNav = (onClick, disabled) => {
    return (
      <button
        className="image-gallery-icon image-gallery-right-nav"
        disabled={disabled}
        onClick={onClick}
        aria-label="Next Slide"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="86" viewBox="0 0 28 86" fill="none">
          <rect width="28" height="86" fill="white" />
          <path d="M11.3109 48.658C11.2123 48.5503 11.1341 48.4223 11.0808 48.2815C11.0275 48.1406 11 47.9897 11 47.8372C11 47.6847 11.0275 47.5337 11.0808 47.3929C11.1341 47.252 11.2123 47.1241 11.3109 47.0164L15.4355 42.499L11.3109 37.9816C11.1121 37.7639 11.0005 37.4687 11.0005 37.1608C11.0005 36.8529 11.1121 36.5577 11.3109 36.34C11.5096 36.1223 11.7792 36 12.0603 36C12.3414 36 12.611 36.1223 12.8098 36.34L17.6891 41.684C17.7877 41.7917 17.8659 41.9196 17.9192 42.0605C17.9725 42.2013 18 42.3523 18 42.5048C18 42.6573 17.9725 42.8083 17.9192 42.9491C17.8659 43.09 17.7877 43.2179 17.6891 43.3256L12.8098 48.6696C12.4058 49.112 11.7255 49.112 11.3109 48.658Z" fill="black" />
        </svg>
      </button>
    );
  };

  return (
    <>
      <ImageGallery
        items={product?.galleryArr}
        onImageError={addImageFallback}
        showNav={true}
        lazyLoad={true}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
      />
    </>
  );
};

export default Gallery;
