import React, { useEffect } from "react";
import "./recommendedProperties.scss";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { GoArrowUpRight } from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { recommendedPropertiesSelector } from "../../../redux/reducer/recommendedProperties";
import { fetchRecommendedProperties } from "../../../redux/action/recommendedProperties";
import { fetchProduct } from "../../../redux/action/product";
import { Link } from "react-router-dom";
import { fetchNearByProperty } from "../../../redux/action/nearByProperty";
import { borderRadius } from "@mui/system";

const RecommendedProperties = () => {
  const dispatch = useDispatch();
  const { recommendedProperties } = useSelector(recommendedPropertiesSelector);

  useEffect(() => {
    dispatch(fetchRecommendedProperties());
  }, [dispatch]);

  // console.log(`recommendedProperties`, recommendedProperties);
  return (
    <div className="ReconditionsProperties" style={{"margin":"19px auto"}}>

      <div className="text-with-bar" style={{
        "marginLeft": "1px"
      }}>
        <p>  COMMUNITIES </p>
        <hr className="hr" />
      </div>
      {/* head text */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 16,
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ color: "#000000", fontSize: "25px" }}>

          RECOMMENDED PROPERTIES
        </h3>

        {/* buttons */}

        <div className="slider-btn-container">
          <button onClick={() => console.log(Swiper.activeIndex
          )} className={Swiper.activeIndex == 0 ? "recodition-prev-first" : "recondition-prev"}>
          <svg stroke="currentColor" fill="none" stroke-width="1" viewBox="0 0 24 24" aria-hidden="true" height="30" width="35" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
          </button>
          <button className="recondition-next">
          <svg stroke="currentColor" fill="none" stroke-width="1" viewBox="0 0 24 24" aria-hidden="true" height="30" width="35" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
          </button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          marginBottom: "50px",
          marginTop: "5px",
        }}
      >
        <Swiper
          slidesPerView={4}
          spaceBetween={2}
          slidesPerGroup={2}
          loopFillGroupWithBlank={false}
          rewind={false}
          speed={1100}
          // grabCursor={true}
          breakpoints={{
            1500: {
              slidesPerView: 5,
              // spaceBetween: 6,
            },
            1400: {
              slidesPerView: 4.7,
              spaceBetween: 10,
            },
            1300: {
              slidesPerView: 4,
              // spaceBetween: 6,
            },
            1200: {
              slidesPerView: 3.8,
              // spaceBetween: 12,
            },
            1100: {
              slidesPerView: 3.5,
              // spaceBetween: 12,
            },
            1000: {
              slidesPerView: 3,
              // spaceBetween: 10,
            },
            890: {
              slidesPerView: 2.8,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 2.5,
              spaceBetween: 8,
            },
            578: {
              slidesPerView: 2,
              spaceBetween: 7,
            },
            424: {
              slidesPerView: 1.2,
              spaceBetween: 3,
            },
            280: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          navigation={{
            nextEl: ".recondition-next",
            prevEl: ".recondition-prev",
          }}
          // navigation= {true}
          modules={[Pagination, Navigation]}
        //   className="mySwiper"
        >
          {recommendedProperties.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="recondition-slider-container TrendingProperties-cards">
                  <Link
                    to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                      }-${item.id}`}
                    className="linkclass"
                  >

                    <div
                      className="recondition-slider-cards"
                      onClick={(e) => dispatch(fetchProduct(item.id))}
                    >


                      <div className="recondition-slider-img" style={{ backgroundImage: `url(${item.proImg})` }}>
                        {/* <img
                          src={item.proImg}
                          alt="card"
                          style={{ width: "100%", borderRadius: "13px" }}
                          /> */}
                        {/* <div className="recondition-price-tag">₹{item.price}</div> */}
                      </div>
                      <div className="recondition-slider-box">
                        <p className="recondition-card-heading" style={{
                          "color": "#000",
                          "fontFamily": "Gotu",
                          "fontSize": "14px",
                          "fontStyle": "normal",
                          "fontWeight": "600",
                          "lineHeight": "normal",
                          "textTransform": "uppercase"
                        }}>
                          {item.name}
                        </p>
                        <div className="recondition-slider-box-con">
                          <div>
                            <p style={{
                              "color": "#000",
                              "fontFamily": "Montserrat",
                              "fontSize": "10px",
                              "fontStyle": "normal",
                              "fontWeight": "500",
                              "lineHeight": "normal",
                              "textTransform": "uppercase"
                            }}>{`BY ${item.DevName}`}</p>
                            <p style={{
                              "color": "#000",
                              "textAlign": "center",
                              "fontFamily": "Montserrat",
                              "fontSize": "10px",
                              "fontStyle": "normal",
                              "fontWeight": "500",
                              "lineHeight": "normal",
                              "textTransform": "uppercase"
                            }}>2BHK FLAT with fully furnished</p>
                          </div>
                          <span className="recondition-slider-arrow">
                            <GoArrowUpRight size={20} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default RecommendedProperties;
