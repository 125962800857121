import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
// import { Carousel } from 'silk-react-slider';
import { CCarousel, CCarouselItem, CImage } from '@coreui/react'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { MdCall, MdOutlineMeetingRoom } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';

import CallIcon from '@mui/icons-material/Call';
import { WhatsApp } from '@mui/icons-material';
import { FaRegHeart } from "react-icons/fa";
import { ImSortAmountDesc } from "react-icons/im";
import { IoIosArrowForward } from "react-icons/io";
import { IoGridSharp } from "react-icons/io5";
import { MdIosShare, MdVerified } from "react-icons/md";
import { RiLayout4Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../asset/images/agent.jpeg";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { fetchProduct } from "../../redux/action/product";
import { allProjectListSelector } from "../../redux/reducer/allProjectList";
import ScrollButton from "../MoveToTopButton/ScrollButton";
import AdvanceFilter from "./AdvanceFilter";
import Navbar from "./Navbar";
import "./style.scss";
import Modal from "./Modal";
import TransitionsModal from "./Modal";
import Shortmodal from "./Shortmodal";
import { BiBuildings, BiRupee } from "react-icons/bi";
import { RxDimensions } from "react-icons/rx";
import { LuSofa } from "react-icons/lu";
import { width } from "@mui/system";

const Property = () => {
  const addImageFallback = (event) => {
    event.currentTarget.src =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjCN1Fg1nNOg8sc76MR64L7ZBPWuYs6c1BGg&usqp=CAU";
  };
  const date = new Date();
  let day = date.getDate();
  let year = date.getFullYear();
  let currentDate = `Feb ${day}, ${year}`;

  const [showUi, setShowUi] = useState(false);
  const handleshowUi = () => {
    setShowUi(true);
  };
  const handleshowUiTwo = () => {
    setShowUi(false);
  };
  const [active, setActive] = useState("");
  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const dispatch = useDispatch();
  const { allProjectList } = useSelector(allProjectListSelector);
  const [page, setPage] = useState(1);

  // infinitescrolling
  useEffect(() => {
    dispatch(fetchAllProjectList("", "", "", "", page));
  }, [page]);

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.offsetHeight;
      const scrollPoint = window.scrollY + windowHeight;
      if (scrollPoint >= fullHeight) {
        setPage((prev) => prev + 1);
        setCard((prev) => [...prev, ...allProjectList]);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  const [card, setCard] = useState([]);
  
  let paramsInUrl = window.location.pathname;

  useEffect(() => {
    if (paramsInUrl.includes("residential-property-in-india")) {
      dispatch(fetchAllProjectList("", "residential", "", "", page));
    } else if (paramsInUrl.includes("commercial-property-in-india")) {
      dispatch(fetchAllProjectList("", "commercial", "", "", page));
    } else if (paramsInUrl.includes("plots-property-in-india")) {
      dispatch(fetchAllProjectList("", "plots", "", "", page));
    } else if (paramsInUrl.includes("office-property-in-india")) {
      dispatch(fetchAllProjectList("", "office", "", "", page));
    } else if (paramsInUrl.includes("penthouse-property-in-india")) {
      dispatch(fetchAllProjectList("", "penthouse", "", "", page));
    } else if (paramsInUrl.includes("shop-property-in-india")) {
      dispatch(fetchAllProjectList("", "shop", "", "", page));
    } else if (paramsInUrl.includes("villa-property-in-india")) {
      dispatch(fetchAllProjectList("", "villa", "", "", page));
    } else if (paramsInUrl.includes("/trending")) {
      dispatch(fetchAllProjectList("", "", "", 1, page));
    } else if (paramsInUrl.includes("property-in" && "residential")) {
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const substringToRemove2 = "/residential";

      const newString = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      const cityNameInUrl = newString.replace(
        new RegExp(substringToRemove2, "g"),
        ""
      );
      dispatch(
        fetchAllProjectList("", "residential", "", "", "", cityNameInUrl)
      );
    } else if (paramsInUrl.includes("property-in" && "commercial")) {
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const substringToRemove2 = "/commercial";

      const newString = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      const cityNameInUrl = newString.replace(
        new RegExp(substringToRemove2, "g"),
        ""
      );
      dispatch(
        fetchAllProjectList("", "commercial", "", "", "", cityNameInUrl)
      );
    } else if (paramsInUrl.includes("/property-in-")) {
      //for city param
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const urlcity = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      dispatch(fetchAllProjectList("", "", "", "", page, urlcity));
    } else if (paramsInUrl.includes("-projects-list")) {
      // for developer param
      const originalString = paramsInUrl;
      const substringToRemove = "-projects-list/";
      const newString = originalString.replace(
        new RegExp(substringToRemove, "g"),
        ""
      );
      let urlDevName = newString.replace(/\//g, "");
      dispatch(fetchAllProjectList("", "", "", "", "", "", urlDevName));
    } else {
      console.log("Path not detected");
      function redirect(path) {
        setTimeout(() => {
          window.location.href = path;
        }, 500);
      }
      redirect("/");
    }
  }, [page, paramsInUrl]);
  //...........//

  // const buttons = document.querySelectorAll("[data-carousel-button]")
  // const nextRef = useRef()
  // const prevRef = useRef()
 
  // buttons.forEach(button => {
  //   button.addEventListener("click", () => {
  //     const offset = button.dataset.carouselButton === "next" ? 1 : -1
  //     const slides = button
  //       .closest("[data-carousel]")
  //       .querySelector("[data-slides]")
  
  //     const activeSlide = slides.querySelector("[data-active]")
  //     let newIndex = [...slides.children].indexOf(activeSlide) + offset
  //     if (newIndex < 0) newIndex = slides.children.length - 1
  //     if (newIndex >= slides.children.length) newIndex = 0
  
  //     slides.children[newIndex].dataset.active = true
  //     delete activeSlide.dataset.active
  //   })
  // })

  // const corButton = () => {
  //   const offset = nextRef ? 1 : -1
  //   const slides = 
  // }



  return (
    <>
      <Navbar />
      <ScrollButton />

      <div className="property">
        <div
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          <div style={{ gridColumn: "span 9" }}>
            <div className="propertypage-header-container">
              <Link to="/" className="linkclass1">
                Home
              </Link>
              <IoIosArrowForward size={20} />
              {window.location.pathname.includes("india") ? (
                <div className="propertypage-header-container">
                  Property in India
                </div>
              ) : (
                <div className="propertypage-header-container">
                  <Link
                    to={`/property-in-${allProjectList[0]?.cityName.toLowerCase()}`}
                    className="linkclass1"
                  >
                    Property in {allProjectList[0]?.cityName}
                  </Link>
                </div>
              )}
            </div>
          </div>

          {/* <div
            style={{
              gridColumn: "span 3",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ fontSize: "12px", color: "#8B8B8B" }}>
              Last Updated: {currentDate}
            </span>
            <div>

            </div>
          </div> */}
        </div>
        <div
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          {/* filter menu Starts */}
          <Box gridColumn="span 3" style={{boxShadow: "none"}}>
            <AdvanceFilter />
          </Box>
          {/* <Box gridColumn="span 4">
            <AdvanceFilter />
          </Box> */}
          <div className="bottom-div">
            <TransitionsModal />
            {/* <Shortmodal/> */}
          </div>
          {/* filter menu Ends */}

          {/* Property details Starts */}
          <Box gridColumn="span 9">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, 1fr)",
                marginBottom: "2%",
              }}
            >
              <div style={{ gridColumn: "span 8" }}>
                <div className="propertypage-header-container">
                  <span style={{
                    "color": "#000",
                    "fontFamily": "Gotu",
                    "fontSize": "36px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "normal",
                    "textTransform": "uppercase"
                  }}>
                    {`${card.length ? card.length : "10"}  search results`}
                  </span>
                </div>
              </div>
              <div
                style={{ gridColumn: "span 4", backgroundColor: "transparent" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div style={{ display: "flex", gap: "5px" }}>
                    <ImSortAmountDesc size={20} />
                    <Typography backgroundColor="#F5FAF3" marginTop="-3px">
                      <span style={{ fontSize: "14px" }}>Sort By</span>
                    </Typography>
                  </div> */}

                  <div className="sort-section">
                    <div className="sort-selection">
                      <form action="#">
                        <select
                          name="sort"
                          id="sort"
                          className="sort-selection--style"
                        >
                          <option value="lowest">Price Low to High</option>
                          <option value="lowest">Relevance</option>
                          <option value="lowest">Newest First</option>

                          <option value="highest">Price High to Low</option>
                          <option value="a-z">
                            price / sq.ft : low to high
                          </option>
                          <option value="z-a">
                            price / sq.ft : high to low
                          </option>
                        </select>
                      </form>
                    </div>
                  </div>
                  <div className="icon-container">
                    <RiLayout4Fill
                      onClick={(event) => {
                        handleshowUi();
                        handleClick(event);
                      }}
                      key={1}
                      id={"1"}
                      className={active === "1" ? "active-layout" : "not-active-layout"}
                    />
                    <IoGridSharp
                      onClick={(event) => {
                        handleshowUiTwo();
                        handleClick(event);
                      }}
                      key={2}
                      id={"2"}
                      className={active === "2" ? "active-layout" : "not-active-layout"}
                    />
                  </div>

                </div>
              </div>
            </div>

            {showUi ? (
              <div className="property-card-main">
                {card.length > 9
                  ? card?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ marginBottom: "15px", marginRight: "15px" }}
                        onClick={(e) => dispatch(fetchProduct(item.id))}
                      >
                        {/* console.log(fetchAllProjectList); */}
                        <Link
                          to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                            }-${item.id}`}
                          className="linkclass"
                        >
                          <div className="property-card">
                            <div className="price-tag">₹ {item.price}</div>
                            <Link
                              to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                                }-${item.id}`}
                            >
                              <img
                                src={item.proImg}
                                alt="property"
                                onError={addImageFallback}
                                style={{
                                  width: "100%",
                                  height: "180px",
                                  borderTopRightRadius: "6px",
                                  borderTopLeftRadius: "6px",
                                }}
                              />
                            </Link>
                            <div className="property-grid-layout-container">
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                }}
                              >
                                <div style={{ gridColumn: "span 10" }}>
                                  <span className="property-name">
                                    {item.name}
                                  </span>
                                </div>
                                <div style={{ gridColumn: "span 2" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      justifyItems: "center",
                                    }}
                                  >
                                    <MdIosShare
                                      color="#FFD194"
                                      size={20}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <FaRegHeart
                                      color="#FFD194"
                                      size={20}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <span className="property-address">
                                {item.location}, {item.cityName} hello
                              </span>
                              <div className="property-detail-grid-container">
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    3 Bhk
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </span>
                                </div>

                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    {item.property_type}{" "}
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </span>
                                </div>
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    {item.area}{" "}
                                  </span>
                                </div>
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Ready to move
                                  </span>
                                </div>
                              </div>

                              <Divider
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "22px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(12, 1fr)",
                                }}
                              >
                                <div
                                  style={{
                                    gridColumn: "span 2",
                                    marginTop: "-15px",
                                  }}
                                >
                                  <IconButton style={{ padding: "0" }}>
                                    <MdVerified
                                      color="#6aa74d"
                                      className="verified-badge"
                                    />
                                    <Avatar alt="Profile" src={agent} />
                                  </IconButton>
                                </div>
                                <span
                                  style={{
                                    gridColumn: "span 6",
                                    marginLeft: "10px",
                                    fontSize: "10px",
                                    color: "#8b8b8b",
                                  }}
                                >
                                  SEEDWILL EXPERT
                                </span>
                                <div
                                  style={{
                                    gridColumn: "span 4",
                                    marginTop: "-14px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    className="contact-btn"
                                    color="success"
                                  >
                                    contact
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                  : allProjectList?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ marginBottom: "15px", marginRight: "15px" }}
                        onClick={(e) => dispatch(fetchProduct(item.id))}
                      >
                        <Link
                          to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                            }-${item.id}`}
                          className="linkclass"
                        >
                          <div className="property-card">
                            <div className="price-tag">₹ {item.price}</div>
                            <Link
                              to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                                }-${item.id}`}
                            >

                              <img
                                src={item.proImg}
                                alt="property"
                                onError={addImageFallback}
                                style={{
                                  width: "100%",
                                  height: "180px",
                                  borderTopRightRadius: "6px",
                                  borderTopLeftRadius: "6px",
                                }}
                              />
                            </Link>
                            <div className="property-grid-layout-container">
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                }}
                              >
                                <div style={{ gridColumn: "span 10" }}>
                                  <span className="property-name">
                                    {" "}
                                    {item.name}
                                  </span>
                                </div>
                                <div style={{ gridColumn: "span 2" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      justifyItems: "center",
                                    }}
                                  >
                                    <MdIosShare
                                      color="#FFD194"
                                      size={20}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <FaRegHeart
                                      color="#FFD194"
                                      size={20}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <span className="property-address">
                                {item.location}, {item.cityName}
                              </span>
                              <div className="property-detail-grid-container">
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    3 Bhk
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </span>
                                </div>

                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    {item.property_type}{" "}
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </span>
                                </div>
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    {item.area}{" "}
                                  </span>
                                </div>
                                <div className="property-detail-grid-item">
                                  <span className="property-detail-subtext">
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                    Ready to move
                                  </span>
                                </div>
                              </div>

                              <Divider
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "22px",
                                }}
                              />
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(5, 1fr)",
                                }}
                              >
                                <div
                                  style={{
                                    gridColumn: "span 2",
                                    marginTop: "-15px",
                                  }}
                                >
                                  <IconButton style={{ padding: "0" }}>
                                    <MdVerified
                                      color="#6aa74d"
                                      className="verified-badge"
                                    />
                                    <Avatar alt="Profile" src={agent} />
                                  </IconButton>
                                </div>
                                <span
                                  style={{
                                    gridColumn: "span 6",
                                    marginLeft: "10px",
                                    fontSize: "10px",
                                    color: "#8b8b8b",
                                  }}
                                >
                                  SEEDWILL EXPERT
                                </span>
                                <div
                                  style={{
                                    gridColumn: "span 4",
                                    marginTop: "-14px",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    className="contact-btn"
                                    color="success"
                                  >
                                    contact
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <>
                {card.length
                  ? card?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => dispatch(fetchProduct(item.id))}
                      >

                        <div className="card-list">
                          <div style={{ display: "flex", width: "100%" }} className="card-item" >
                            <div class="property-listing-carousel">
                              <Carousel>
                                <div>
                                    <img src={item.proImg} />
                                </div>
                                <div>
                                    <img src={item.proImg} />
                                </div>
                                <div>
                                    <img src={item.proImg} />
                                </div>
                              </Carousel>
                            </div>

                            <div className="card-list-container">
                              <Link
                                to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                                  }-${item.id}`}
                                className="linkclass" style={{width: "100%"}}
                              >
                                <div className="container8">
                                  <div className="column">
                                    <p
                                      style={{
                                        "color": "rgb(0, 0, 0)",
                                        "fontFamily": "Gotu",
                                        "fontSize": "2vw",
                                        "fontStyle": "normal",
                                        "fontWeight": "400",
                                        "lineHeight": "normal",
                                        "letterSpacing": "0.96px",
                                        "textTransform": "uppercase",
                                        "margin": "0"
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                style={{
                                  "color": "#000",
                                  "fontFamily": "Montserrat",
                                
                                  "fontStyle": "normal",
                                  "fontWeight": "400",
                                  "lineHeight": "normal",
                                  "letterSpacing": "0.48px",
                                  "textTransform": "uppercase",
                                  "margin": "0"
                                }}
                              >
                                {item.DevName}{" "}{item.location},{" "}
                                {item.cityName}
                              </p>
                                  </div>
                                  <div className="listing-icon-container">
                                  <div className="column2">
                                    <MdIosShare
                                      color="#FFD194"
                                      size={20}
                                      style={{
                                        "width": "16px",
                                        "height": "20.999px",
                                        "flexShrink": "0",
                                        "fill": "#000",
                                        "paddingRight": "15px",
                                        "stroke": "#000"
                                      }}
                                    /></div>
                                  <div className="column3">
                                    <FaRegHeart
                                      color="#FFD194"
                                      size={20}
                                      style={{
                                        "width": "16px",
                                        "height": "20.999px",
                                        "flexShrink": "0",
                                        "fill": "#000",
                                        "strokeWidth": "0.3px",
                                        "stroke": "#000"
                                      }}
                                    />
                                  </div>
                                  </div>
                                </div>
                              </Link>

                             
                              <div>
                              
                                <div className="five-two-column-row">
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <BiRupee style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        PRICE
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.price}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <MdOutlineMeetingRoom style={{fontSize:"15px", marginRight: "2px"}} />    
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        ROOM
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.bhk}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <BiBuildings style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        PROPETY TYPE
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.property_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <LuSofa style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px",
                                          "textTransform": "uppercase"
                                        }}
                                      >
                                        FURNISHING
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.furnishing}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <RxDimensions style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        AREA (SQ.FT)
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.area}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                  }}
                                >
                                  {item.desc ? (
                                    item.desc
                                  ) : (
                                    <>
                                      {item.name} by {item.DevName} offering
                                      premium Flats in {item.location}{" "}
                                      {item.cityName} starting from{" "}
                                      {item.price} Onwards.
                                    </>
                                  )}

                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#6aa74d ",
                                    }}
                                  >
                                    read more
                                  </span>
                                </p> */}




                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                  justifyContent: "center"
                                }}
                              >
                                <div
                                  style={{
                                    "display": "flex",
                                    "gap": "10px",
                                    "fontSize": "12px",
                                    "color": "rgb(139, 139, 139)",
                                    "justifyContent": "space-between",
                                    "width": "100%",
                                    "height": "32px"
                                  }}
                                >

                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <MdCall style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      CALL
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <FaWhatsapp style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      WhatsApp
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      "flexGrow": "1",    
                                      "border": "1px solid rgb(0, 0, 0)",
                                      "cursor": "pointer",
                                      "flexShrink": "0",
                                      "border": "1px solid #000",
                                      "background": "#000"
                                    }}
                                  >

                                    <span style={{
                                      "color": "#fff",
                                      "textAlign": "center",
                                      "fontFamily": "Montserrat",
                                      "fontStyle": "normal",
                                      "fontWeight": "600",
                                      "lineHeight": "normal",
                                      "textTransform": "uppercase"
                                    }}>ENQUIRE</span>

                                  </button>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    );
                  })
                  : allProjectList.map((item, index) => {
                    return (
                      <div
                        // className="card-list"
                        key={index}
                        onClick={() => dispatch(fetchProduct(item.id))}
                      >

                        <div className="card-list">
                          <div style={{ display: "flex", width: "100%" }} className="card-item" >
                            <div class="property-listing-carousel">
                            <Carousel>
                              <div>
                                  <img src={item.proImg} />
                              </div>
                              <div>
                                  <img src={item.proImg} />
                              </div>
                              <div>
                                  <img src={item.proImg} />
                              </div>
                            </Carousel>
                            </div>

                            <div className="card-list-container">
                              <Link
                                to={`/property-in-${item.cityName.toLowerCase()}/${item.slug
                                  }-${item.id}`}
                                className="linkclass" style={{width: "100%"}}
                              >
                                <div className="container8">
                                  <div className="column" style={{padding:"3px"}}>
                                    <p
                                      style={{
                                        "color": "rgb(0, 0, 0)",
                                        "fontFamily": "Gotu",
                                        "fontSize": "2vw",
                                        "fontStyle": "normal",
                                        "fontWeight": "400",
                                        "lineHeight": "normal",
                                        "letterSpacing": "0.96px",
                                        "textTransform": "uppercase",
                                        "margin": "0"
                                      }}
                                    >
                                      {item.name}
                                    </p>
                                    <p
                                style={{
                                  "color": "#000",
                                  "fontFamily": "Montserrat",
                                
                                  "fontStyle": "normal",
                                  "fontWeight": "400",
                                  "lineHeight": "normal",
                                  "letterSpacing": "0.48px",
                                  "textTransform": "uppercase",
                                  "margin": "0"
                                }}
                              >
                                {item.DevName}{" "}{item.location},{" "}
                                {item.cityName}
                              </p>
                                  </div>
                                  <div className="listing-icon-container">
                                  <div className="column2">
                                    <MdIosShare
                                      color="#FFD194"
                                      className="column8icon"
                                      style={{
                                        "width": "16px",
                                        "height": "20.999px",
                                        "flexShrink": "0",
                                        "fill": "#000",
                                        "paddingRight": "15px",
                                        "stroke": "#000"
                                      }} 
                                    /></div>
                                  <div className="column3">
                                    <FaRegHeart
                                      color="#FFD194"
                                      className="column8icon"
                                      style={{
                                        "width": "16px",
                                        "height": "20.999px",
                                        "flexShrink": "0",
                                        "fill": "#000",
                                        "strokeWidth": "0.3px",
                                        "stroke": "#000"
                                      }}
                                    />
                                  </div>
                                  </div>
                                </div>
                              </Link>

                              
                              <div>
                              
                                <div className="five-two-column-row">
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <BiRupee style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        PRICE
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.price}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <MdOutlineMeetingRoom style={{fontSize:"15px", marginRight: "2px"}} />    
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        ROOM
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.bhk}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <BiBuildings style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        PROPETY TYPE
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.property_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <LuSofa style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px",
                                          "textTransform": "uppercase"
                                        }}
                                      >
                                        FURNISHING
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.furnishing}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="two-column-row">
                                    <div className="left-column">
                                      <RxDimensions style={{fontSize:"15px", marginRight: "2px"}} />
                                      <span
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "400",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        AREA (SQ.FT)
                                      </span>
                                    </div>
                                    <div className="right-column">
                                      <p
                                        style={{
                                          "color": "#000",
                                          "fontFamily": "Montserrat",
                                          "fontStyle": "normal",
                                          "fontWeight": "500",
                                          "lineHeight": "normal",
                                          "letterSpacing": "0.48px"
                                        }}
                                      >
                                        {item.area}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#8B8B8B",
                                  }}
                                >
                                  {item.desc ? (
                                    item.desc
                                  ) : (
                                    <>
                                      {item.name} by {item.DevName} offering
                                      premium Flats in {item.location}{" "}
                                      {item.cityName} starting from{" "}
                                      {item.price} Onwards.
                                    </>
                                  )}

                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#6aa74d ",
                                    }}
                                  >
                                    read more
                                  </span>
                                </p> */}




                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                  justifyContent: "center"
                                }}
                              >
                                <div
                                  style={{
                                    "display": "flex",
                                    "gap": "10px",
                                    "fontSize": "12px",
                                    "color": "rgb(139, 139, 139)",
                                    "justifyContent": "space-between",
                                    "width": "100%",
                                    "height": "32px"
                                  }}
                                >

                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <MdCall style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      CALL
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <FaWhatsapp style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      WhatsApp
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      "flexGrow": "1",    
                                      "border": "1px solid rgb(0, 0, 0)",
                                      "cursor": "pointer",
                                      "flexShrink": "0",
                                      "border": "1px solid #000",
                                      "background": "#000"
                                    }}
                                  >

                                    <span style={{
                                      "color": "#fff",
                                      "textAlign": "center",
                                      "fontFamily": "Montserrat",
                                      "fontStyle": "normal",
                                      "fontWeight": "600",
                                      "lineHeight": "normal",
                                      "textTransform": "uppercase"
                                    }}>ENQUIRE</span>

                                  </button>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    );
                  })}
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Property;
