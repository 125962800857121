import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { MdIosShare } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import HDFC from "../../../asset/images/hdfc.png";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { MdCall, MdOutlineMeetingRoom } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import bhk from "../../../asset/images/overview/ICON 15 X 156/Unit Configuration.png";
import builder from "../../../asset/images/overview/builder.svg";
import minprice from "../../../asset/images/overview/ICON 15 X 156/Starting Price.png";
import project from "../../../asset/images/overview/ICON 15 X 156/Project Area.png";
import projectsize from "../../../asset/images/overview/projectsize.svg";
import propertytype from "../../../asset/images/overview/ICON 15 X 156/Property Type.png";
import furnished from "../../../asset/images/overview/ICON 15 X 156/Project Area.png";
import Rera from "../../../asset/images/overview/ICON 15 X 156/Rera ID.png";
import Vector from "../../../asset/images/overview/ICON 15 X 156/Possession Status.png";
import { productSelector } from "../../../redux/reducer/product";
import ContactFrom from "./Actionbar";
import FloorPlan from "./FloorPlan";
import Gallery from "./Gallery/Gallery";
import "./propertydetails.scss";
import SimilarProjects from "./SimilarProjects/SimilarProjects";
import brousher from "../../../asset/images/brousher2.png";
import vector1 from "../../../asset/images/Vector (1).svg";

const PropertyDetails = () => {
  const { product } = useSelector(productSelector);
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    setFaqs(product?.faqArr);
  }, []);

  function toggleAccordionItem(index) {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          return {
            ...faq,
            open: !faq.open,
          };
        } else {
          return faq;
        }
      })
    );
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  //slidervalue handler starts
  const [value, setValue] = React.useState(25);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };
  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = useState(false);

  const handlepanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const date = new Date();
  let day = date.getDate();
  let year = date.getFullYear();
  let currentDate = `Feb ${day}, ${year}`;

  const [swiperRef, setSwiperRef] = useState(null);

  const KeyFeatures = product?.projectDetails?.key_feature;
  const whythis = product?.projectDetails?.why_this;
  const MoreDetails = product?.projectDetails?.raw_desc;
  const [showFullContent, setShowFullContent] = useState(false);

  const keyFeaturesHtml = product?.projectDetails?.raw_desc || '';
  const keyFeaturesText = new DOMParser().parseFromString(keyFeaturesHtml, 'text/html').body.textContent;

  const truncatedText = keyFeaturesText.substring(0, 200); // Adjust the number of characters to truncate

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <>
      <div className
        ="gallery3">

        {product?.galleryArr?.length > 0 ? (

          <Gallery />

        ) : (
          ""
        )}
      </div>

      {/*New component */}
      <div className="main-item" style={{ "marginTop": "-18px" }}>

        <div className="property-heading-container">
          <div className="property-heading">
            <div className="main-property-heading">

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(12, 1fr)",
                }}
              >
              </div>
              <div className="heading-text-container">
                <span
                  style={{
                    "color": "#000",
                    "fontFamily": "Gotu",
                    "fontSize": "24px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "normal",
                    "letterSpacing": "0.96px",
                    "textTransform": "uppercase"
                  }}
                >
                  {product?.projectDetails?.name}
                </span>

                <div className="property-price-text-container">
                  {/* <span className="emi-text">EMI starts at ₹14.82 K</span> */}

                  <span className="property-price-text">
                    <div className="text-with-bar">
                      <p style={{
                        "display": "inline-block",
                        "marginRight": "139px",
                        "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "12px",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.48px",
                        "textTransform": "uppercase",
                        "marginBottom": "-15px"
                      }}> PRICE </p>
                      <hr className="hr" />
                    </div></span>

                </div>
              </div>
              <div className="property-name-subtext-container">
                <span className="property-name-subtext" style={{
                  "color": "#000",
                  "fontFamily": "Montserrat",
                  "fontSize": "12px",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "lineHeight": "normal",
                  "letterSpacing": "0.48px",
                  "textTransform": "uppercase",
                  "marginTop": "10px"
                }}>

                  {product?.projectDetails?.name.split(' ')[0]} located in {product?.projectDetails?.location}
                </span>
                <span className="emi-text"> <span style={{
                  "color": "#000",
                  "fontFamily": "Gotu",
                  "fontSize": "24px",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "lineHeight": "normal",
                  "letterSpacing": "0.96px",
                  "textTransform": "uppercase"
                }}>INR</span>    <span style={{
                  "color": "#CFAE73",
                  "fontFamily": "Gotu",
                  "fontSize": "24px",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "lineHeight": "normal",
                  "letterSpacing": "0.96px",
                  "textTransform": "uppercase"
                }}>{product?.projectDetails?.price}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr" />
        <div className="maindiv">
          <div className="maindetails">


            <div className="overview-container">
              <div className="overview">
                <p
                  style={{
                    "color": "#000",
                    "fontFamily": "Gotu",
                    "fontSize": "24px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "normal",
                    "letterSpacing": "0.96px",
                    "textTransform": "uppercase"
                  }}
                >
                  DETAILS
                </p>
                <div className="main-overview">

                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={minprice}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          PRICE
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.price}
                      </div>
                    </div>
                  </div>

                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img src={bhk} alt="banner" className="overview-images" />
                        <span className="overview-text-heading">
                          unit configuration
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.bhk}
                      </div>
                    </div>
                  </div>
                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={propertytype}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          PROPETY TYPE
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.property_type}
                      </div>
                    </div>
                  </div>
                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={furnished}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          FURNISHED
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.furnishing}
                      </div>
                    </div>
                  </div>
                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={project}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          AREA (SQ.FT)
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.area}
                      </div>
                    </div>
                  </div>
                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={Vector}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          Possession Status
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.possession}
                      </div>
                    </div>
                  </div>
                  <div className="five-two-column-row" style={{
                    "marginLeft": "-18px"
                  }}>
                    <div className="two-column-row">
                      <div className="left-column" style={{
                        "marginTop": "-11px", "color": "#000",
                        "fontFamily": "Montserrat",
                        "fontSize": "14px!important ",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "lineHeight": "normal",
                        "letterSpacing": "0.56px"
                      }}>
                        <img
                          src={Rera}
                          alt="banner"
                          className="overview-images"
                        />
                        <span className="overview-text-heading">
                          Rera
                        </span>
                      </div> <div className="right-column" style={{
                        "fontFamily": "Gotu",
                        "alignItems": "flex-end"
                      }}>

                        {product?.projectDetails?.rera}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="key-features-container">
              <div className="key-features">
                <p >
                  <span
                    style={{
                      "color": "#000",
                      "fontFamily": "Gotu",
                      "fontSize": "24px",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "lineHeight": "normal",
                      "letterSpacing": "0.96px",
                      "textTransform": "uppercase"
                    }}
                  >
                    about project
                  </span>

                </p>
                <div className="main-key-features">
                  <div
                  // dangerouslySetInnerHTML={{ __html: KeyFeatures }}
                  // className="htmlfrombackhand"
                  />
                  <p>
                    {showFullContent ? keyFeaturesText : truncatedText}
                    {keyFeaturesText.length > truncatedText.length && (
                      <button
                        style={{

                          "fontSize": "0.8rem",
                          "fontWeight": "500",
                          "color": "#318980",
                          // "backgroundColor": "#d6e7e6",
                          "border": "1px solid #d6e7e6",
                          "borderRadius": "0.2rem",
                          // "padding": "0.4rem 1.5rem",
                          "marginTop": "0.5rem",
                          "cursor": "pointer",
                          "transition": "all 0.5s ease"
                        }}
                        className="read-more-button"
                        onClick={toggleShowFullContent}
                      >
                        {showFullContent ? 'Read Less' : 'Read More'}
                      </button>

                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="amenities-container">
              <div className="amenities">
                <p
                  style={{
                    "color": "#000",
                    "fontFamily": "Gotu",
                    "fontSize": "24px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "normal",
                    "letterSpacing": "0.96px",
                    "textTransform": "uppercase"
                  }}
                >
                  Amenities
                </p>
                <div className="main-amenities">
                  <div className="amenities-grid-container" style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                    {product?.amenityArr?.map((item, index) => (
                      <div className="amenities-grid-item" key={index} style={{
                        flexBasis: "45%",
                        "padding": "7px 0px 6px 0px"
                      }}>
                        <div style={{ display: "flex", }}>
                          <img
                            style={{
                              "marginRight": "12px"
                            }}
                            src={item?.icon}
                            alt="amenities"
                            className="amenities-images"
                          />
                          <span className="amenities-text-heading" style={{
                            "color": "#1D221E",
                            "textAlign": "center",
                            "fontFamily": "Gotu",
                            "fontSize": "18px",
                            "fontStyle": "normal",
                            "fontWeight": "400",
                            "lineHeight": "normal",
                            "textTransform": "uppercase",
                            "gap": "2.5%"
                          }}>
                            {item?.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {product?.faqArr?.length > 0 ? (
              <div className="why-this-property-container">
                <div className="why-this-property">
                  <p style={{ fontFamily: "sans-serif", color: "#000000" }}>
                    <span
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: "5px",
                        textDecorationThickness: "2px",
                        textDecorationColor: "#FFD194",
                      }}
                    >
                      Frequentl
                    </span>
                    y Asked Questions
                  </p>
                  <div className="main-faq">
                    {faqs?.map((faq, index) => (
                      <div key={index} className="faq-accordion">
                        <button onClick={() => toggleAccordionItem(index)}>
                          {faq.faqQ}
                        </button>
                        {faq.open && <p>{faq.faqA}</p>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

          </div>

          <div className="actionbar-container">

          <div
                                style={{
                                  display: "flex",
                                  marginTop: "10px",
                                  justifyContent: "center"
                                }}
                              >
                                <div
                                  style={{
                                    "display": "flex",
                                    "gap": "10px",
                                    "fontSize": "12px",
                                    "color": "rgb(139, 139, 139)",
                                    "justifyContent": "space-between",
                                    "width": "100%",
                                    "height": "32px"
                                  }}
                                >

                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <MdCall style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      CALL
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      flexGrow: "1",
                                      border: '1px solid rgb(0, 0, 0)',
                                      cursor: 'pointer',
                                      flexShrink: '0',
                                      backgroundColor: '#ffff',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}

                                  >
                                    <FaWhatsapp style={{ marginRight: '8px' }} />
                                    <span
                                      style={{
                                        color: '#000',
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textTransform: 'uppercase',
                                      }}
                                    >
                                      WhatsApp
                                    </span>
                                  </button>
                                  <button className="card-button"
                                    style={{
                                      "flexGrow": "1",
                                      "border": "1px solid rgb(0, 0, 0)",
                                      "cursor": "pointer",
                                      "flexShrink": "0",
                                      "border": "1px solid #000",
                                      "background": "#000"
                                    }}
                                  >

                                    <span style={{
                                      "color": "#fff",
                                      "textAlign": "center",
                                      "fontFamily": "Montserrat",
                                      "fontStyle": "normal",
                                      "fontWeight": "600",
                                      "lineHeight": "normal",
                                      "textTransform": "uppercase"
                                    }}>ENQUIRE</span>

                                  </button>
                                </div>


                              </div>

            {product?.floorPlanArr?.length > 0 ? (
              <FloorPlan />
            ) : (
              ""
            )}
            <div className="brosher">
              <p >
                <span
                  style={{
                    "color": "#000",
                    "fontFamily": "Gotu",
                    "fontSize": "24px",
                    "fontStyle": "normal",
                    "fontWeight": "400",
                    "lineHeight": "normal",
                    "letterSpacing": "0.96px",
                    "textTransform": "uppercase"
                  }}
                >
                  BROCHURE
                </span>

              </p>
              <div className="image-container">
                <img
                  src={brousher}
                  alt="Floeeplan"
                  className="floeeplan-image"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="154"
                  height="154"
                  viewBox="0 0 154 154"
                  fill="none"
                  className="center-svg"
                >
                  <circle cx="50" cy="50" r="50" fill="white" />

                </svg>
                <img
                  style={{
                    "width": "43px",
                    "height": "44px",
                    "flexShrink": "0"
                  }}
                  src={vector1}
                  alt="Floeeplan"
                  className="centered-image"
                />

              </div>
              <button style={{
                "width": "453px",
                "border": "1px solid rgb(0, 0, 0)",
                "cursor": "pointer",
                "height": "52px",
                "flexShrink": "0",
                "background": "rgb(0, 0, 0)",
                "top": "20px",
                "marginTop": "14px!important"
              }}
              ><span style={{
                "color": "#fff",
                "textAlign": "center",
                "fontFamily": "Montserrat",
                "fontSize": "14px!important",
                "fontStyle": "normal",
                "fontWeight": "600",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>download
                </span></button>
            </div>
            <div className="map-container">
              <div className="map">
                <p >
                  <span
                    style={{
                      "color": "#000",
                      "fontFamily": "Gotu",
                      "fontSize": "24px",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "lineHeight": "normal",
                      "letterSpacing": "0.96px",
                      "textTransform": "uppercase"
                    }}
                  >
                    LOCATION
                  </span>

                </p>
                <div>
                  <iframe
                    title="Map"
                    className="main-map"
                    src={product?.projectDetails?.pro_map}
                  />
                </div>
                <div>

                </div>
              </div>
            </div>
            {/* <ContactFrom /> */}
          </div>
        </div >
      </div>
    </>
  );
};
export default PropertyDetails;

