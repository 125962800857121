import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { FaChevronDown } from 'react-icons/fa6'
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { maxHeight } from "@mui/system";

const propertyList = ["Residential", "Commercial", "Office", "Shop", "Plots", "Penthouse", "Villa"]

const bhkList = ["1RK/ 1BHK", "2BHK", "3BHK", "4BHK", "5BHK"]

const amenitiesList = ["Gated Community", "Lift", "Swimming Pool", "Gym", "Parking", "Gas Pipeline"]


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  /*  marginTop: "-12px", */
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AdvanceFilter = () => {
  const [expandedBudget, setExpandedBudget] = useState(false);
  const [expandedPropertyType, setExpandedPropertyType] = useState(false);
  const [expandedBhkType, setExpandedBhkType] = useState(false);
  const [expandedLocality, setExpandedLocality] = useState(false);
  const [expandedSaleType, setExpandedSaleType] = useState(false);
  const [expandedConstructionStatus, setExpandedConstructionStatus] =
    useState(false);
  /* const [expandedBathrooms, setExpandedBathrooms] = useState(false); */
  const [expandedArea, setExpandedArea] = useState(false);
  const [expandedAmenities, setExpandedAmenities] = useState(false);
  const [expandedAge, setExpandedAge] = useState(false);
  const [expandedFacing, setExpandedFacing] = useState(false);
  const [expandedDetail, setExpandedDetail] = useState(false);
  const [expandedRera, setExpandedRera] = useState(false);
  const [active, setActive] = useState("");
  const [bhkType, setBhkType] = useState("")
  const [amenitiesType, setAmenitiesType] = useState("")


  

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const handleExpandBudget = () => {
    setExpandedBudget(!expandedBudget);
  };
  const handleExpandPropertyType = () => {
    setExpandedPropertyType(!expandedPropertyType);
  };
  const handleExpandBhkType = () => {
    setExpandedBhkType(!expandedBhkType);
  };
  const handleExpandLocality = () => {
    setExpandedLocality(!expandedLocality);
  };
  const handleExpandSaleType = () => {
    setExpandedSaleType(!expandedSaleType);
  };
  const handleExpandConstructionStatus = () => {
    setExpandedConstructionStatus(!expandedConstructionStatus);
  };
  /* const handleExpandBathrooms = () => {
    setExpandedBathrooms(!expandedBathrooms);
  }; */
  const handleExpandArea = () => {
    setExpandedArea(!expandedArea);
  };
  const handleExpandAmenities = () => {
    setExpandedAmenities(!expandedAmenities);
  };
  const handleExpandAge = () => {
    setExpandedAge(!expandedAge);
  };
  const handleExpandFacing = () => {
    setExpandedFacing(!expandedFacing);
  };
  const handleExpandDetail = () => {
    setExpandedDetail(!expandedDetail);
  };
  const handleExpandRera = () => {
    setExpandedRera(!expandedRera);
  };

  const [min, setMin] = useState("");
  const [max, setMax] = useState("40000000");

  const [range, setRange] = useState([min, max]);

  const handleMin = (event) => {
    setMin(event.target.value)
  }

  const handleMax = (event) => {
    setMax(event.target.value)
  }

  const handleBhk = (e) => {
    if (e.target.checked) {
      setBhkType(e.target.value)
      console.log(propertyType)
    } else setBhkType("")

  }

  const handleAmenities = (e) => {
    if (e.target.checked) {
      setAmenitiesType(e.target.value)
    } else setAmenitiesType("")

  }



  const customMarks = [
    {
      value: 3000000,
      label: "₹30L",
    },
    {
      value: 10000000,
      label: "₹1CR",
    },
    {
      value: 20000000,
      label: "₹2CR",
    },
    {
      value: 30000000,
      label: "₹3CR",
    },
    {
      value: 40000000,
      label: "₹4CR",
    },
  ];

  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("5000")

  const handleMinArea = (event) => {
    setMinArea(event.target.value)
  }

  const handleMaxArea = (event) => {
    setMaxArea(event.target.value)
  }


  /* const handleRangeArea = (event) => {
    setRangeArea(event.target.value);
    dispatch(
      fetchAllProjectList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        rangeArea[0],
        rangeArea[1]
      )
    );
  }; */

  const customMarksArea = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1000,
      label: "1000",
    },
    {
      value: 2000,
      label: "2000",
    },
    {
      value: 3000,
      label: "3000",
    },
    {
      value: 4000,
      label: "4000",
    },
    {
      value: 5000,
      label: "5000+",
    },
  ];
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    // Fetch data from API and set locations state
    fetch(`https://nodeapi.seedwill.co/api/v1/locationList?city=${1}`)
      .then((response) => response.json())
      .then((data) => setLocations(data.data))
      .catch((error) => console.log(error));
  }, []);

  const handleLocationSelect = (event) => {
    setSelectedLocations(event.target.value);
    dispatch(
      fetchAllProjectList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        selectedLocations
      )
    );
  };

  const [propertyType, setPropertyType] = useState("")

  const handleChangeType = (e) => {
    if (e.target.checked) {
      setPropertyType(e.target.value)
      console.log(propertyType)
    } else setPropertyType("")
  }





  const filterProperty = () => {
    dispatch(fetchAllProjectList("", propertyType, "", "", "", "", "", min, max, minArea, maxArea, "", bhkType, amenitiesType))
  }

  const clearAll = () => {
    setPropertyType("")
    setMin("")
    setMax("")
    setMinArea("")
    setMaxArea("")
    setBhkType("")
    setAmenitiesType("")
    dispatch(fetchAllProjectList("", "", "", "", "", "", "", "", "", "", "", "", "", ""))
  }

  return (
    <>
      <div className="advance-filter-container">
        <div className="filter-text-heading" style={{ alignSelf: "self-start", margin: "20px 0 20px 8px" }}>
          <h3 className="filter-header-text" style={{
            "color": "#000",
            "fontFamily": "Gotu",
            "fontSize": "36px",
            "fontStyle": "normal",
            "fontWeight": "400",
            "lineHeight": "normal"
          }}>FILTER</h3>
        </div>

        <div className={`advance-filter-wrapper ${expandedBudget ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box">
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Budget Range
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedBudget}
                  onClick={handleExpandBudget}
                  aria-expanded={expandedBudget}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <div>
            <Collapse in={expandedBudget}>
              <div style={{ padding: "30px 10px" }}>
                <select className="filter-budget-select" onChange={handleMin}>
                  <option value="1000000">10 Lacs</option>
                  <option value="2000000">20 Lacs</option>
                  <option value="3000000">30 Lacs</option>
                  <option value="4000000">40 Lacs</option>
                  <option value="5000000">50 Lacs</option>
                  <option value="6000000">60 Lacs</option>
                  <option value="7000000">70 Lacs</option>
                  <option value="8000000">80 Lacs</option>
                  <option value="9000000">90 Lacs</option>
                  <option value="10000000">1 Cr</option>
                  <option value="20000000">2 Cr</option>
                  <option value="30000000">3 Cr</option>
                  <option value="40000000">4 Cr</option>
                  <option value="50000000">5 Cr</option>
                  <option value="60000000">6 Cr</option>
                  <option value="70000000">7 Cr</option>
                  <option value="80000000">8 Cr</option>
                  <option value="90000000">9 Cr</option>
                  <option value="400000000">10 Cr+</option>
                </select>
                <span style={{ fontSize: "13px" }}>To</span>
                <select className="filter-budget-select" onChange={handleMax}>
                  <option value="9000000">90 Lacs</option>
                  <option value="10000000">1 Cr</option>
                  <option value="20000000">2 Cr</option>
                  <option value="30000000">3 Cr</option>
                  <option value="40000000">4 Cr</option>
                  <option value="50000000">5 Cr</option>
                  <option value="60000000">6 Cr</option>
                  <option value="70000000">7 Cr</option>
                  <option value="80000000">8 Cr</option>
                  <option value="90000000">9 Cr</option>
                  <option value="10000000">10 Cr+</option>
                </select>
              </div>
            </Collapse>
          </div>
        </div>

        <div className={`advance-filter-wrapper ${expandedPropertyType ? 'color' : 'nope'}`}>
          <div className={`advance-filter-type-box`}>
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Property Type
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedPropertyType}
                  aria-expanded={expandedPropertyType}
                  onClick={handleExpandPropertyType}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <Collapse in={expandedPropertyType}>
            <div className={`checkbox-wrapper-filter ${expandedPropertyType ? 'color' : 'nope'}`} id="cb1">
              {propertyList?.map((item, idx) => {
                return (
                  <div className="checkbox-con" >
                    <label htmlFor={item.id}>{item}</label>
                    <input type="checkbox" id={item.id} checked={propertyType === item} value={item}
                      onChange={handleChangeType} />
                  </div>
                )
              })}
            </div>
          </Collapse>
        </div>

        <div className={`advance-filter-wrapper ${expandedBhkType ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box" marginTop={"20px"}>
            <div className="filter-text-heading">Bhk Type
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedBhkType}
                  aria-expanded={expandedBhkType}
                  onClick={handleExpandBhkType}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <Collapse in={expandedBhkType}>
            <div className={`checkbox-wrapper-filter ${expandedBhkType ? 'color' : 'nope'}`} id="cb1">
              {bhkList?.map((item, idx) => {
                return (
                  <div className="checkbox-con" >
                    <label htmlFor={item.id}>{item}</label>
                    <input type="checkbox" id={item.id} checked={bhkType == idx + 1} value={idx + 1}
                      onChange={handleBhk} />
                  </div>
                )
              })}
            </div>
          </Collapse>
        </div>

        <div className={`advance-filter-wrapper ${expandedLocality ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box" marginTop={"20px"}>
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Locality
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedLocality}
                  aria-expanded={expandedLocality}
                  onClick={handleExpandLocality}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <Collapse in={expandedLocality} >
            <div style={{ padding: "30px 0" }}>
              <select
                // multiple
                value={selectedLocations}
                onChange={handleLocationSelect}
                className="select"
              >
                {locations.map((item, index) => {
                  return (
                    <option key={item.index} value={item.location}>
                      {item.location}
                    </option>
                  );
                })}
              </select>
            </div>
          </Collapse>
        </div>

        {/* <div className="advance-filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Construction Status
          <div className="filter-div">
          <span className="filter-span">Select Any</span>
          <ExpandMore
            expand={expandedConstructionStatus}
            aria-expanded={expandedConstructionStatus}
            onClick={handleExpandConstructionStatus}
            >
            <FaChevronDown className="expandmore-button" />
          </ExpandMore>
          </div>  
          </div>
        </div>
        <Collapse in={expandedConstructionStatus} timeout="auto" unmountOnExit>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Under Construction
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Ready to Move
            </button>
          </div>
        </Collapse>
 */}
        {/* <p className="header-text">Features</p>

        <div className="advance-filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Bathrooms
          <div className="filter-div">
          <span className="filter-span">Select Any</span>
          <ExpandMore
            expand={expandedBathrooms}
            aria-expanded={expandedBathrooms}
            onClick={handleExpandBathrooms}
            >
            <FaChevronDown className="expandmore-button" />
          </ExpandMore>
          </div>
          </div>
        </div>
        <Collapse in={expandedBathrooms}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              1+
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              onClick={handleClick}
            >
              2+
            </button>
            <button
              key={3}
              id={"3"}
              className={active === "3" ? "active" : "not-active"}
              onClick={handleClick}
            >
              3+
            </button>
            <button
              key={4}
              id={"4"}
              className={active === "4" ? "active" : "not-active"}
              onClick={handleClick}
            >
              4+
            </button>
            <button
              key={5}
              id={"5"}
              className={active === "5" ? "active" : "not-active"}
              onClick={handleClick}
            >
              5+
            </button>
          </div>
        </Collapse> */}

        <div className={`advance-filter-wrapper ${expandedArea ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box" >
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Build Up Area (Sq. Ft.)
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedArea}
                  aria-expanded={expandedArea}
                  onClick={handleExpandArea}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <div>
            <Collapse in={expandedArea}>
              <div style={{ padding: "30px 10px" }}>
                <select className="filter-budget-select" onChange={handleMinArea}>
                  <option value="1000000">0</option>
                  <option value="200">200</option>
                  <option value="400">400</option>
                  <option value="600">600 </option>
                  <option value="800">800</option>
                  <option value="1000">1000</option>
                  <option value="1500">1500</option>
                  <option value="2000">2000</option>
                  <option value="2500">2500</option>
                  <option value="3000">3000</option>
                  <option value="3500">3500</option>
                  <option value="4000">4000</option>
                  <option value="4500">4500</option>
                  <option value="5000">5000</option>
                </select>
                <span style={{ fontSize: "13px" }}>To</span>
                <select className="filter-budget-select" onChange={handleMaxArea}>
                  <option value="200">200</option>
                  <option value="400">400</option>
                  <option value="600">600 </option>
                  <option value="800">800</option>
                  <option value="1000">1000</option>
                  <option value="1500">1500</option>
                  <option value="2000">2000</option>
                  <option value="2500">2500</option>
                  <option value="3000">3000</option>
                  <option value="3500">3500</option>
                  <option value="4000">4000</option>
                  <option value="4500">4500</option>
                  <option value="5000">5000</option>
                </select>
              </div>
            </Collapse>
          </div>
          {/* <div>
            <Collapse in={expandedArea}>
              <div style={{ padding: "0px 15px 0px 10px" }}>
                <Slider
                  valueLabelDisplay="auto"
                  step={100}
                  marks={customMarksArea}
                  min={0}
                  max={5000}
                  value={rangeArea}
                  onChange={handleRangeArea}
                />
              </div>
            </Collapse>
          </div> */}
        </div>

        <div className={`advance-filter-wrapper ${expandedAmenities ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box" marginTop={"20px"}>
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Amenities
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedAmenities}
                  aria-expanded={expandedAmenities}
                  onClick={handleExpandAmenities}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <Collapse in={expandedAmenities}>
            <div className={`checkbox-wrapper-filter ${expandedAmenities ? 'color' : 'nope'}`} id="cb1">
              {amenitiesList?.map((item, idx) => {
                return (
                  <div className="checkbox-con" >
                    <label htmlFor={item.id}>{item}</label>
                    <input type="checkbox" id={item.id} checked={amenitiesType == item} value={item}
                      onChange={handleAmenities} />
                  </div>
                )
              })}
            </div>
          </Collapse>
        </div>

        <div className={`advance-filter-wrapper ${expandedRera ? 'color' : 'nope'}`}>
          <div className="advance-filter-type-box">
            <div className="filter-text-heading" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "12px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal"
            }}>Rera Approved
              <div className="filter-div">
                <span className="filter-span">Select Any</span>
                <ExpandMore
                  expand={expandedRera}
                  aria-expanded={expandedRera}
                  onClick={handleExpandRera}
                >
                  <FaChevronDown className="expandmore-button" />
                </ExpandMore>
              </div>
            </div>
          </div>
          <Collapse in={expandedRera}>
            <div className={`checkbox-wrapper-filter rera ${expandedRera ? 'color' : 'nope'}`}>
              <div className="checkbox-con-filter">
                <input type="checkbox"></input>
                <label>All</label>
              </div>
              <div className="checkbox-con-filter">
                <input type="checkbox"></input>
                <label>RERA Approved Properties</label>
              </div>
              <div className="checkbox-con-filter">
                <input type="checkbox"></input>
                <label>RERA Registered Dealers</label>
              </div>
            </div>
          </Collapse>
        </div>
        <button className="filter-button" onClick={filterProperty} style={{
          "color": "#FFF",
          "fontFamily": "Montserrat",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "600",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>FILTER PROPERTIES</button>
        <button className="clear-all-button" onClick={clearAll} style={{
          "color": "#000",
          "fontFamily": "Montserrat",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "500",
          "lineHeight": "normal",
          "textTransform": "uppercase"
        }}>CLEAR ALL</button>
      </div>
    </>
  );
};

export default AdvanceFilter;
