import React from 'react'
import bottom from "../../../asset/images/bottom2.png";
import { Link } from "react-router-dom";
import "./style.scss";
function BnnerText() {
  const myInlineStyles = {
    position: 'absolute',
    width: '92px',
    top: '33%',
    marginLeft: '167px',
    strokeWidth: '1px',
    stroke: '#9D9D9C',
  };
  return (
    <div className="PropertyServices">
      <div className="container">
        <div className="layout">
          <p className="col col-main">
            {/* <h5 className="first">A DIGITAL EXPERIENCE</h5> */}
            <div className="text-with-bar" style={{
              "display": "inline-block",
              "position": "relative",
              "marginLeft": "4px",
              "marginBottom": "-22px"
            }}>
              <p> A DIGITAL EXPERIENCE </p>
              <hr className="hr" style={myInlineStyles} />
            </div>
            <h1 className="second" style={{
              "color": "rgb(0, 0, 0)",
              "fontFamily": "Gotu",
              "fontSize": "26px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "textTransform": "uppercase",
              "marginTop": "5px"
            }}>MANAGE YOUR PROPERTY LISTING 24/7, WITH THE SEEDWILL</h1>
            <p className="third" style={{
              "color": "#000",
              "fontFamily": "Montserrat",
              "fontSize": "15px",
              "fontStyle": "normal",
              "fontWeight": "400",

             
            }}>Selling property is no more a challenge  with seedwill.selling a house has become easy with the internet and easier with us! we will handle the process of selling your property from start to finish</p>
            <br />
            <button className="custom-btn btn-2" style={{ border: 'none' }}>
              {/* <div className="svg-wrapper-1">
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="currentColor"
            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
          />
        </svg>
      </div>
    </div> */}
              <span>PROPERTY LIST</span>
            </button>

          </p>
          <p className="col col-complementary" role="complementary" style={{
            "marginTop": "19px"
          }}>
            <img src={bottom} alt="banner" style={{ width: "100%", height: "279px" }} />

          </p>
        </div>
      </div>

    </div>
  )
}

export default BnnerText