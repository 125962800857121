import React, { useEffect } from "react";
import "./style.scss";

import { Link } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdvanceFilter from './AdvanceTab';
import AdvanceTab from './AdvanceTab';
import TabProperty from './TabProperty';
import { fetchAllProjectList } from "../../../redux/action/allProjectList";
import { useDispatch, useSelector } from "react-redux";

import { trendingPropertiesSelector } from "../../../redux/reducer/trendingProperties";
import { fetchTrendingProperties } from "../../../redux/action/trendingProperties";

import { fetchProduct } from "../../../redux/action/product";


function ExcluciveProperties() {
  const dispatch = useDispatch();
  const { trendingProperties } = useSelector(trendingPropertiesSelector);

  useEffect(() => {
    dispatch(fetchTrendingProperties());
  }, [dispatch]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const styles = {
    marginTop: '-65px',
    // You can add more CSS properties here if needed
  };


  return (
    <>
      <div className="ReconditionsProperties" style={styles}>
        <div className="text-with-bar" style={{
          "display": "inline-block",
          "position": "relative",
          "marginLeft": "0px",
          "marginBottom": "-22px"
        }}>
          <p> categories </p>
          <hr className="hr" />
        </div>

        <div className="grid-container2">
          <div className="item">

            <div class=" column1" style={{
              "padding": "0px!important"
            }}>
              EXPLORE CATEGORIES
            </div>
          </div>
          <div className="item" style={{
            "zIndex": "999"
          }}>

            <span className="text-at-bottom">
              <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                <AdvanceTab />
              </Box>
            </span>
          </div>
        </div>
        <TabProperty />


        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',


          }}
        >
          <div
            className="btn-group"
            onClick={() => dispatch(fetchAllProjectList("", "", "", 1))}
          >
            <Link to={`/property-in-india/trending`}>
              <button className="custom-btn btn-2" style={{
                "width": "222px",
                "height": "40px",
                "color": "#fff",
                "padding": "1px 25px",
                "background": "#000",
                "cursor": "pointer",
                "display": "inline-block",
                "outline": "none",
                "fontSize": "15px"
              }}>VIEW ALL PROPERTIES</button>
            </Link>

          </div>
          {/* Your content here */}
        </Box>


      </div>
    </>
  )
}

export default ExcluciveProperties;




