import Searchbar from "../Searchbar/Searchbar"
import "./HomeBanner.css"

export default function HomeBanner() {
  return (
    <div className="banner-con">
        <Searchbar />
    </div>
  )
}
