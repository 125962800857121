import React from 'react'
import { Link } from "react-router-dom";
import "./style.scss";
import new1 from "../../../asset/images/new1.jpg";
import new2 from "../../../asset/images/new2.png";
import new3 from "../../../asset/images/new3.jpg";
function New() {
    return (
        <div className="PropertyServices" style={{
            "width": "78% !important",
            "margin": "0 auto !important",
            "backgroundColor": "transparent",
            "marginTop": "60px !important"
        }}>


            <div className="text-with-bar" style={{
                "display": "inline-block",
                "position": "relative",
                "marginLeft": "18px!important",
                "marginBottom": "-42px"
            }}>
                <p> New </p>
                <hr />
            </div>

            {/* head text */}

            <div className="grid-container">
                <div className="grid-item">
                    <div className="container">
                        <div className="card">
                            <img src={new1} alt="banner" style={{
                                "width": "374px",
                                "height": "314px",
                                "flexShrink": "0"
                            }} />
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-title" style={{
                                        "margin": "-18px"
                                    }}>
                                        <h2 style={{
                                            "color": "rgb(0, 0, 0)",
                                            "fontFamily": "Gotu",
                                            "fontSize": "26px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "normal",
                                            "textTransform": "uppercase",
                                           
                                        }}>TRY OUR CUSTOMER SOLUTION</h2>
                                    </div>

                                </div>

                                <p className='content1' style={{
                                    "color": "#000",
                                    "fontFamily": "Montserrat",
                                    "fontSize": "15px",
                                    "fontStyle": "normal",
                                    "fontWeight": "400",
                                    "lineHeight": "normal",

                                }}>
                                    We offer a full spectrum of customer-oriented is are services  from the ground up till the execution
                                </p>
                                <div className="btn-group">
                                    <button className="custom-btn btn-2" style={{
                                        "marginLeft": "-10px"
                                    }}>TRY NOW</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="container">
                        <div className="card">
                            <img src={new3} alt="banner" style={{
                                "width": "374px",
                                "height": "314px",
                                "flexShrink": "0"
                            }} />
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-title" style={{
                                        "margin": "-18px"
                                    }}>
                                        <h2 style={{
                                            "color": "rgb(0, 0, 0)",
                                            "fontFamily": "Gotu",
                                            "fontSize": "26px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "normal",
                                            "textTransform": "uppercase",
                                           
                                        }}>EXPLORE TOP<br></br> PROJECTS</h2>
                                    </div>

                                </div>

                                <p className='content1' style={{
                                    "color": "#000",
                                    "fontFamily": "Montserrat",
                                    "fontSize": "14px",
                                    "fontStyle": "normal",
                                    "fontWeight": "400",
                                    "lineHeight": "normal",

                                }}>
                                    Explore our portfolio of top projects<br />
                                </p>

                                <Link to="/property-in-india">
                                    <button className="custom-btn btn-2" style={{
                                        "marginLeft": "-10px"
                                    }}>READ MORE</button>
                                </Link>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="container">
                        <div className="card">
                            <img src={new2} alt="banner" style={{
                                "width": "374px",
                                "height": "314px",
                                "flexShrink": "0"
                            }} />
                            <div className="card-body">
                                <div className="row">
                                    <div className="card-title" style={{
                                        "margin": "-18px"
                                    }}>
                                        <h2 style={{
                                            "color": "rgb(0, 0, 0)",
                                            "fontFamily": "Gotu",
                                            "fontSize": "26px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "normal",
                                            "textTransform": "uppercase",
                                           
                                        }}>DISCOVER NEWS &<br></br> ARTICLES</h2>
                                    </div>

                                </div>

                                <p className='content1' style={{
                                    "color": "#000",
                                    "fontFamily": "Montserrat",
                                    "fontSize": "14px",
                                    "fontStyle": "normal",
                                    "fontWeight": "400",
                                    "lineHeight": "normal",

                                }}>
                                    Click to read our real state news & information that matters
                                </p>
                                <Link to="/blog">
                                    <div className="btn-group">
                                        <button className="custom-btn btn-2" style={{
                                            "marginLeft": "-10px"
                                        }}>READ MORE</button>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default New;