import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { FaSquareFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin } 
  from "react-icons/fa6";
import ScrollButton from "../MoveToTopButton/ScrollButton";
import blogs from "../../asset/images/blog.png";
import recentpost from "../../asset/images/recentpost.png";

import { useDispatch, useSelector } from "react-redux";
import { blogSelector } from "../../redux/reducer/blog";
import "./style.scss";
import { fetchBlog } from "../../redux/action/blog";
import { fetchBlogContent } from "../../redux/action/blogContent";
import { recentBlogsSelector } from "../../redux/reducer/recentBlogs";
import { fetchRecentBlogs } from "../../redux/action/recentBlogs";
import { color } from "@chakra-ui/react";

const Blog = () => {
  const dispatch = useDispatch();
  const { blog } = useSelector(blogSelector);
  const { recentBlogs } = useSelector(recentBlogsSelector);

  // console.log(`recentBlogs`, recentBlogs);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    dispatch(fetchBlog());
    dispatch(fetchRecentBlogs());
  }, [dispatch]);

  const [showText, setShowText] = useState(false);

  const toggleShowText = () => {
    setShowText(!showText);
  };

  const [limitNo, setlimitNo] = useState(5)

  const handleSeeMore = () => {
    setlimitNo(limitNo + 5)
  }

  useEffect(() => {
    dispatch(fetchBlog(limitNo));
  }, [limitNo])

  return (
    <div style={{overflowX: "hidden"}}>
      <ScrollButton />
      <Header />
      <div className="blog">
        {/* <img src={blogs} alt="bcklogo" className="blog-banner" /> */}
        <div className="img-banner">
          <div className="banner-text">
            <div style={{display:"flex", alignItems: "center"}}><p style={{marginRight:"8px", width:"max-content", color:"#9d9d9c"}}>SeedWill Latest Update</p>
              <div style={{width:"37%", height: "max-content"}}><hr style={{borderTop: '1px solid #9d9d9c'}}></hr></div>
            </div>  
            <h4 style={{margin:"0px", width:"max-content"}}>BLOG</h4>
            <p style={{maxWidth:"35vw"}}>Lorem ipsum dolor sit amet consectetur, 
              adipisicing elit. Recusandae facilis quo, blanditiis voluptatibus, 
             </p>
          </div>
        </div>
        

        <div className="blogs-model-box" /* style={{ marginTop: "20px" }} */>
          <div style={{display: "flex", flexDirection: "column", flex: "3", borderRight: "1px solid #9d9d9c"}}>
            <p className="blogs-model-heading" style={{
                  padding: "10px 0",
                  fontWeight: "500",
                  fontFamily: "Gotu",
                  color: "#000000"
                }}>Discover News & Articles</p>
            {blog.length > 0
              ? blog.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="blogs-model"
                      onClick={() => dispatch(fetchBlogContent(item.id))}
                    >
                      <Link to={`/blog/${item.slug}/`} className="linkclass">
                        <div className="blog-list" style={{ display: "flex", width: "100%"}}>
                          <div style={{flex: "3 1", position: "relative"}}>
                            <img
                              src={item.img_url}
                              alt="card"
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%"
                              }}
                            />
                            <div className="blog-card-read">Read +</div>
                          </div>

                          <div className="blog-list-container" style={{flex: "3 1", display: "flex", flexDirection: "column" }}>
                            <div style={{display:"flex", alignItems: "center"}}><p style={{fontSize: "1.3em", color: "#9d9d9c" , marginRight:"8px"}}>{item.created}</p>
                              <div style={{width:"37%", height: "max-content"}}><hr style={{borderTop: '1px solid #9d9d9c'}}></hr></div>
                            </div>
                            <p
                              style={{ fontSize: "2.3em", fontWeight: "500", fontFamily: "'Gotu', sans-serif", marginBottom: "0"}}
                            >
                              {item.title}
                            </p>
                            <p
                              style={{
                                color: "#505050",
                                fontSize: "1.3em",
                                fontWeight: "500",
                                fontFamily: "Montserrat",
                                overflow: "hidden",
                                marginBottom: "11px"
                                // display: "inline-block",
                                // whiteSpace: "nowrap"
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item?.post_content
                              }}
                              className="htmlfrombackhand"
                            >{/* {item.post_content} */}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              : ""}
              <div className="seemore-btn">
                <button onClick={handleSeeMore}>SEE MORE</button>
              </div>
          </div>

          <div style={{flex: "1"}}>
            <div className="most-recent-post">
              <h2
                className="blogs-model-heading"
                style={{
                  padding: "10px",
                  fontWeight: "500",
                  fontFamily: "Gotu",
                  color: "#000000",
                  width: "max-content"
                }}
              >
                Most Recent Post
              </h2>

              <div style={{ padding: "22px 0" }}>
                {recentBlogs?.map((item, index) => {
                  return (
                    <Link to={`/blog/${item.slug}/`} className="linkclass">
                      <div
                        className="most-recent-card"
                        key={index}
                        onClick={() => dispatch(fetchBlogContent(item.id))}
                      >
                        <img
                          src={item.img_url}
                          alt="recent-post"
                          className="most-recent-img"
                          // onError={addImageFallback}
                        />

                        <div className="most-recent-container">
                          <p>{item.title}</p>
                          {/* <p style={{ color: "#8B8B8B" }}>{item.created}</p> */}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>

              <div className="recent-post-social">
                <h2 className="blogs-model-heading" style={{marginTop: "1em"}}>FOLLOW US</h2>
                <div className="icons-container">
                  <FaSquareFacebook className="recent-social-icons"/>
                  <FaTwitter className="recent-social-icons"/>
                  <FaYoutube className="recent-social-icons"/>
                  <FaInstagram className="recent-social-icons"/>
                </div>
              </div>
            </div>

            <div>
              {/* <div className="subscribe-card">
                <div style={{ paddingLeft: "10px" }}>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      color: "#000000",
                    }}
                  >
                    Subscribe
                  </p>
                  <input type="email" placeholder="enter your email id" />
                  <input
                    type="submit"
                    style={{ color: "#FFFFFF", background: "#6AA74D" }}
                  />
                </div>
              </div> */}

              <div
                className="most-recent-post"
                style={{ marginTop: "10px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
