import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productSelector } from "../../../../redux/reducer/product";
import "./style.scss";

const TabButton = ({ label, active, onClick }) => (
  <button
    className={`tab-button ${active ? "active-tab" : ""}`}
    onClick={onClick}
  >
    {label}
  </button>
);
const FloorPlan = () => {
  const { product } = useSelector(productSelector);
  let floorPlan = product?.floorPlanArr;
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(floorPlan);
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="floor-plan-container">
      <div className="floor-plan">
        <p style={{ fontFamily: "sans-serif", color: "#000000" }}>
          <span
            style={{
              "color": "#000",
              "fontFamily": "Gotu",
              "fontSize": "24px",
              "fontStyle": "normal",
              "fontWeight": "400",
              "lineHeight": "normal",
              "letterSpacing": "0.96px",
              "textTransform": "uppercase"
            }}
          >
            floor plan
          </span>

        </p>
        <div className="main-floor-plan">
          <div className="tabs">
            <div className="tab-buttons">
              {data?.map((item, index) => (
                <TabButton
                  key={index}
                  label={item?.name}
                  active={activeTab === index}
                  onClick={() => handleTabClick(index)}
                />
              ))}
            </div>
            <div className="right-div">{product?.projectDetails?.area}</div>
            <div className="floor-plan-image-container">
              <img
                className="floor-plan-image"
                src={data[activeTab]?.image}
                alt="Activeimage"
              />
            </div>
            <button

              style={{
                "width": "446px",
                "border": "1px solid rgb(0, 0, 0)",
                "cursor": "pointer",
                "height": "52px",
                "flexShrink": "0",
                "border": "1px solid #000",
                "background": "#000"
              }}
            >

              <span style={{
                "color": "#fff",
                "textAlign": "center",
                "fontFamily": "Montserrat",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "600",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>download</span>

            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;
