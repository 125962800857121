import React, { useState, useEffect } from "react";

import sitemap from "../../asset/images/infopage/sitemap.png";
import services1 from "../../asset/images/services1.png";
import services2 from "../../asset/images/services2.png";
import services3 from "../../asset/images/services3.png";
import services4 from "../../asset/images/services4.png";
import Footer from "../Footer/index";
import Header from "../Header";
import ScrollButton from "../MoveToTopButton/ScrollButton";
import image1 from "../../asset/images/1image.svg";
import image2 from "../../asset/images/image2.svg";
import image3 from "../../asset/images/image3.svg";
import image4 from "../../asset/images/image4.svg";
import service from "../../asset/images/service-group.jpg";
import "./style.scss";

const SiteMap = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  const initialDropdowns = Array(5).fill(false);
  const [openStates, setOpenStates] = useState(initialDropdowns);

  const toggleDropdown = (index) => {
    const updatedStates = [...openStates];
    updatedStates[index] = !updatedStates[index];
    setOpenStates(updatedStates);
  };

  const dropdownItems = [
    'How can I access these property services?',
    'How much time does it take to get these services?',
    'Can I get a personal loan from Seedwill?',
    'What facilities will I get in property management?',
    'Are property legal services handled by professional lawyers?'
  ];

  const dropdownContents = [
    'Content for Item 1',
    'Content for Item 2',
    'Content for Item 3',
    'Content for Item 4',
    'Yes, you will have to execute a contract/agreement with us at the time of taking over the possession of the property. The contract will contain all the terms and conditions of the renting arrangement. We also need documents including a copy of PAN Card, Aadhar card, and Voter Card, prior to delivery of items.'
  ];

  return (
    <>
      <ScrollButton />
      <Header />
      <div>
        {/* <img src={sitemap} alt="bcklogo" className="site-map-banner" />
        <h1 className="banner-imager-text"> <svg xmlns="http://www.w3.org/2000/svg" width="780" height="150" viewBox="0 0 780 189" fill="none">
  <path d="M0 0H746L780 47.25V94.5V189H0V0Z" fill="white"/>
</svg>


</h1> */}

        <div className="banner-with-patch">
          <img src={sitemap} className="banner-image" />
          <div className="patch-details" >
            <svg
              className="background-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="650"
              height="189"
              viewBox="0 0 780 189"
              fill="none"
            >
              <path
                d="M0 0H746L780 47.25V94.5V189H0V0Z"
                fill="white"
              />
            </svg>
            <div className="content">
              <p style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "32px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>services</p>

              <div className="text-container">
                <p style={{ "color": "#8B8B8B", "fontFamily": "Montserrat", "fontSize": "12px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "normal", "textTransform": "capitalize" }}>
                  One-Stop Solution for all Real Estate
                </p>
                <div className="horizontal-line"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="site-map-maindiv" style={{ marginTop: "36px" }}>
        <div >
          <div className="two-column-container">
            <div className="text-column">
              < h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>our expertise </h2>
              <p style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "182.4%",
                "textTransform": "lowercase"
              }}>Lorem ipsum dolor sit amet consectetur. Tempor amet in elit nunc amet dolor at velit pellentesque. Venenatis lobortis nibh sit nulla tincidunt auctor. Amet mauris faucibus ut vitae nunc massa eget. Sit ornare nunc egestas sed quis. Lacus sit et in habitasse erat. Tincidunt nulla tortor sed nisl sem. Viverra magna eget vitae faucibus quisque fames faucibus. Amet pharetra in dolor scelerisque libero amet augue eget. Libero cursus at arcu sit non tempor adipiscing turpis mauris. Ut nunc aliquam vitae at. Laoreet augue amet mollis faucibus vitae varius condimentum malesuada lacinia. A faucibus aliquam hendrerit porta ut mauris mauris velit.
                Amet mauris in lacus amet turpis sit pulvinar et nullam. Augue nunc placerat sed volutpat amet dictumst. Iaculis adipiscing lobortis blandit donec. Nullam cras arcu et donec sodales dictumst purus. Sed tempor quis commodo ac in eu.</p>
              <button

                style={{
                  "width": "170px",
                  "border": "1px solid rgb(0, 0, 0)",
                  "cursor": "pointer",
                  "height": "42px",
                  "flexShrink": "0",
                  "background": "rgb(0, 0, 0)",
                  "marginTop": "30px"
                }}
              >

                <span style={{
                  "color": "#fff",
                  "textAlign": "center",
                  "fontFamily": "Montserrat",
                  "fontSize": "14px",
                  "fontStyle": "normal",
                  "fontWeight": "600",
                  "lineHeight": "normal",
                  "textTransform": "uppercase"
                }}>contact us</span>

              </button>
            </div>
            <div className="image-column">
              <div className="row2">
                <div className="column70">
                  <img src={service} alt="Image 1" style={{
                    "width": "554.959px",
                    "height": "339.787px",
                    "flexShrink": "0",
                    "bottom": "20px",
                    "marginBottom": "101px"
                  } } />
                </div>
                {/* <div className="column30">
                  <img src={image2} alt="Image 2" style={{
                    "width": "282.615px",
                    "height": "163.443px",
                    "flexShrink": "0"
                  }} />
                </div> */}
              </div>
              {/* <div className="row2">
                <div className="column70">
                  <img src={image3} alt="Image 2" style={{
                    "width": "528.959px",
                    "height": "175.475px",
                    "flexShrink": "0"
                  }} />
                </div>
                <div className="column30">
                  <img src={image4} alt="Image 2" style={{
                    "width": "282.615px",
                    "height": "163.443px",
                    "flexShrink": "0"
                  }} />
                </div>
              </div> */}
            </div>
          </div>

          <div className="three-column-container">
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>1.</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>Home Loan</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "textAlign": "justify",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "uppercase"
              }}>We hav <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "12px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "lowercase"
              }}>e tie-ups with some of the leading banks to help you avail home loan with minimum documentation & easy loan repayment methods.</span></h2>

            </div>
          </div>
          <hr />
          <div className="three-column-container">
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>2.</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>Property Management</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "textAlign": "justify",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "uppercase"
              }}>We offe <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "12px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "lowercase"
              }}> r end to end property management services & bring you the most profitable & risk-free investments in real estate to help you capitalise on your investment.</span></h2>

            </div>
          </div>
          <hr />
          <div className="three-column-container">
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>3.</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>resale</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "textAlign": "justify",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "uppercase"
              }}>We evaluat <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "12px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "lowercase"
              }}> e your property rate, set the best property rate & find a suitable buyer for your property.</span></h2>

            </div>
          </div>
          <hr />
          <div className="three-column-container">
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>4.</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "fontFamily": "Gotu",
                "fontSize": "24px",
                "fontStyle": "normal",
                "fontWeight": "400",
                "lineHeight": "normal",
                "textTransform": "uppercase"
              }}>Property Listing</h2>

            </div>
            <div className="column">
              <h2 style={{
                "color": "#000",
                "textAlign": "justify",
                "fontFamily": "Montserrat",
                "fontSize": "13px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "uppercase"
              }}>We brin <span style={{
                "color": "#000",
                "fontFamily": "Montserrat",
                "fontSize": "12px",
                "fontStyle": "normal",
                "fontWeight": "500",
                "lineHeight": "161.4%",
                "textTransform": "lowercase"
              }}> g both residential & commercial properties to the market for sale & rent through a hassle-free process.</span></h2>

            </div>
          </div>
          <hr />

          {/* <p className="page-heading">
            <span
              style={{
                fontFamily: "Prata",
                textDecoration: "underline",
                textUnderlineOffset: "15px",
                textDecorationThickness: "2px",
                textDecorationColor: "#FFD194",
                color: "#000000",
              }}
            >
              Services
            </span>
          </p> */}
          {/* <p className="services-below-text">
            We offer a full spectrum of customer-oriented services from the
            ground up till the execution. We provide our customers with valuable
            & forward-looking insights to help them make informed decisions. Our
            wide array of services includes:
          </p> */}
          {/* <div className="services-grid-container">
            <div className="services-grid-item">
              <div className="services-slider-cards">
                <div className="services-price-tag">Home Loan</div>
                <img src={services1} alt="card" className="services-card-img" />
                <p className="services-card-text">
                  We have tie-ups with some of the leading banks to help you
                  avail home loan with minimum documentation & easy loan
                  repayment methods.
                </p>
              </div>
            </div>
            <div className="services-grid-item">
              <div className="services-slider-cards">
                <div className="services-price-tag">Property Management</div>
                <img src={services2} alt="card" className="services-card-img" />
                <p className="services-card-text">
                  We offer end to end property management services & bring you
                  the most profitable & risk-free investments in real estate to
                  help you capitalise on your investment.
                </p>
              </div>
            </div>
            <div className="services-grid-item">
              <div className="services-slider-cards">
                <div className="services-price-tag">Resale</div>
                <img src={services3} alt="card" className="services-card-img" />
                <p className="services-card-text">
                  We evaluate your property rate, set the best property rate &
                  find a suitable buyer for your property.
                </p>
              </div>
            </div>
            <div className="services-grid-item">
              <div className="services-slider-cards">
                <div className="services-price-tag">Property Listing</div>
                <img src={services4} alt="card" className="services-card-img" />
                <p className="services-card-text">
                  We bring both residential & commercial properties to the
                  market for sale & rent through a hassle-free process.
                </p>
              </div>
            </div>
          </div> */}

          <div className="container2" style={{ textAlign: 'center', marginRight: '21%' }}>
            <h2 style={{
              color: '#000',
              fontFamily: 'Gotu',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.96px',
              textTransform: 'uppercase',
            }}>Frequently Asked Questions</h2>
          </div>
          {dropdownItems.map((item, index) => (
            <div className="dropdown-container" key={index}>
              <div className="dropdown">
                <div className="dropdown-toggle" onClick={() => toggleDropdown(index)}>
                  <span className="dropdown-text" style={{
                    "color": "rgba(29, 34, 30, 0.89)",
                    "fontFamily": "Montserrat",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "600",
                    "lineHeight": "191.4%",
                    "textTransform": "uppercase"
                  }}>{item}</span>
                  <span className="dropdown-toggle-sign">  {openStates[index] ? '-' : '+'}</span>
                </div>
                <hr />
                {openStates[index] && (
                  <div className="dropdown-content">
                    {/* Display the specific content for this dropdown */}
                    <p>{dropdownContents[index]}</p>
                  </div>
                )}
              </div>
              {/* Horizontal Line */}
            </div>
          ))}

          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Vertically align the content
            // Adjust this value to control the centering
          }}>
            <button
              style={{
                "width": "505px",
                "height": "52px",
                cursor: "pointer",
                height: "42px",
                flexShrink: "0",
                background: "rgb(0, 0, 0)",
                marginTop: "20px",
                marginBottom: "50px",
                alignItems: "center",
                display: "flex", // Add display flex to enable centering of text
                justifyContent: "center",
                "border": "1px solid #000",
                "background": "#FFF" // Center the text horizontally
              }}

            >
              <span style={{
                color: "#000",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                textTransform: "uppercase",
              }}>see more </span>
            </button>
          </div>











        </div>
      </div>
      <Footer />
    </>
  );
};

export default SiteMap;
