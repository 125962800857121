// src/actions/projectActions.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Replace 'YOUR_API_ENDPOINT' with the actual endpoint
const API_ENDPOINT = 'https://nodeapi.seedwill.co/api/v1/allProjectList';

// Action to fetch all projects
export const fetchProjects = createAsyncThunk('project/fetchProjects', async () => {
  try {
    const response = await axios.get(API_ENDPOINT);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const setSelectedPropertyType = (propertyType) => ({
    type: 'SET_SELECTED_PROPERTY_TYPE',
    payload: propertyType,
  });

  export const setSelectedCity = (city) => ({
    type: 'SET_SELECTED_CITY',
    payload: city,
  });
