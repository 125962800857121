import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaSquareFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa6";
import DiscoverHome from "../../asset/images/discoverhome.png";
import footerBuilding from "../../asset/images/footer.svg";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { fetchCity } from "../../redux/action/cityName";
import { fetchDeveloper } from "../../redux/action/developer";
import { citySelector } from "../../redux/reducer/cityName";
import { developerSelector } from "../../redux/reducer/developer";
import "./style.scss";

function Footer() {
  const dispatch = useDispatch();
  const { cityName } = useSelector(citySelector);
  const { developer } = useSelector(developerSelector);

  useEffect(() => {
    dispatch(fetchCity());
    dispatch(fetchDeveloper());
  }, [dispatch]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };

  return (
    <div className="footer-main">
      <div className="footer-socials">
        <div  className="footer-left">
          <div className="comName">
            <p>SeedWill</p>
          </div>
          <div className="footer-social-icons">
            <div className="social-con">
              <FaSquareFacebook size={18} className="social-icons"/>
              <FaTwitter size={18} className="social-icons"/>
              <FaYoutube size={18} className="social-icons"/>
              <FaInstagram size={18} className="social-icons"/>
              <FaLinkedin size={18} className="social-icons"/>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <input className="email-input" placeholder="Enter email"></input>
          <button>SUBSCRIBE</button>
        </div>
      </div>
      <div className="footer-building-container">
        {/* <div className="footer-footerBuilding"> */}
          <div className="footer-footerCity">
            <div className="footer-ul-container">
              <ul>Top City</ul>
              <ul className="footer-ul">
                {" "}
                {cityName?.map((item, index) => {
                  return (
                    // <Link to="/property">

                    <Link to={`/property-in-${item.cityName.toLowerCase()}`}>
                      <div
                        key={index}
                        onClick={() => {
                          dispatch(fetchAllProjectList(item.id));
                          scrollToTop(); // Scroll to the top
                        }}
                      >
                        <li className="footer-li" style={{ color: "#a6a6a6" }}>
                          Property in{" "}
                          <span
                            style={{
                              textTransform: "capitalize",
                              color: "#a6a6a6",
                            }}
                            value={item.cityName}
                          >
                            {item.cityName}
                          </span>
                        </li>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div className="footer-ul-container">
              <ul>Developers</ul>
              <ul className="footer-ul">
                {" "}
                {developer.map((item, index) => {
                  return (
                    <Link
                      to={`/${item.name
                        .toLowerCase()
                        .replaceAll(/\s/g, "-")}-projects-list/`}
                    >
                      <div
                        key={index}
                        onClick={() =>
                          dispatch(fetchAllProjectList("", "", item.id))
                        }
                      >
                        <li className="footer-li">
                          <span
                            style={{
                              textTransform: "capitalize",
                              color: "#a6a6a6",
                            }}
                          >
                            {item.name}
                          </span>
                        </li>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div className="footer-ul-container">
              <ul>Residential Property in Top City</ul>
              <ul className="footer-ul">
                {cityName?.map((item, index) => {
                  return (
                    <Link
                      to={`/property-in-${item.cityName.toLowerCase()}/residential`}
                    >
                      <div
                        key={index}
                        onClick={() =>
                          dispatch(fetchAllProjectList(item.id, "residential"))
                        }
                      >
                        <li className="footer-li" style={{ color: "#a6a6a6" }}>
                          Residential Property in{" "}
                          <span
                            style={{
                              textTransform: "capitalize",
                              color: "#a6a6a6",
                            }}
                            value={item.cityName}
                          >
                            {item.cityName}
                          </span>
                        </li>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div className="footer-ul-container">
              <ul> Commercial Property in Top City</ul>
              <ul className="footer-ul">
                {" "}
                {cityName?.map((item, index) => {
                  return (
                    <Link
                      to={`/property-in-${item.cityName.toLowerCase()}/commercial`}
                    >
                      <div
                        key={index}
                        onClick={() =>
                          dispatch(fetchAllProjectList(item.id, "commercial"))
                        }
                      >
                        <li className="footer-li" style={{ color: "#a6a6a6" }}>
                          Commercial Property in{" "}
                          <span
                            style={{
                              textTransform: "capitalize",
                              color: "#a6a6a6",
                            }}
                            value={item.cityName}
                          >
                            {item.cityName}
                          </span>
                        </li>
                      </div>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div>
              <ul>Quick Links</ul>
              <ul className="footer-ul">
                <Link to="/about-us" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    About-Us
                  </li>
                </Link>
                <Link to="/contact-us" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    Contact US
                  </li>
                </Link>
                <Link to="/career" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    Career
                  </li>
                </Link>
                <Link to="/our-services" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    Services
                  </li>
                </Link>
                <Link to="/how-we-work" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    How we Work
                  </li>
                </Link>
                <Link to="/site-map" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    Site Map
                  </li>
                </Link>
                {/* <li className="footer-li"> Property in India</li> */}
                <Link to="/privacy-policy" className="linkclass">
                  <li className="footer-li" style={{ color: "#a6a6a6" }}>
                    {" "}
                    Privacy Policy
                  </li>
                </Link>
                <li className="footer-li"> Terms And Conditions</li>
                <li className="footer-li"> Plots No. 51, Sector 18</li>
                <li className="footer-li"> Contact</li>
                <li className="footer-li">Gurgaon, Haryana</li>
                <li className="footer-li"> 122008+ 91-9205-735-63</li>
                <li className="footer-li"> Contact@Seedwill.Co</li>
              </ul>
            </div>
          {/* </div> */}
          
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-con">  
          <p>COPYRIGHT SEEDWILL CONSULTING PVT LTD 2023</p>
          <p>PRIVACY POLICY</p>
          <p>TERMS & CONDITIONS</p>
          <p>COUNTRY & LANGUAGE</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
